import { Button } from '@mui/material';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from '../../../util/router';

const ModalAddDocument = ({ show, handleClose, handleSave, documents = [] }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    reset({});
  }, [show]);

  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(handleSave)}>
          <Modal.Header closeButton>Add Document</Modal.Header>
          <Modal.Body>
            {documents.length
              ? documents.map((item) => (
                  <Form.Group key={`${item.id}-${Math.random() * 1000}`}>
                    <Form.Check
                      label={`${item.documentId} - ${item.location}`}
                      {...register(item.id)}
                    />
                  </Form.Group>
                ))
              : ''}
            <Form.Group>
              <Button component={Link} to="/inventory/settings/documents">
                + Add new document
              </Button>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalAddDocument;
