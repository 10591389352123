import { getApp, getApps, initializeApp } from 'firebase/app';

function getConfiguration(hostname) {
  if (hostname.match(/buckeye./)) {
    return {
      apiKey: 'AIzaSyA43dVFfez9ZC9sTFU34B7uJm8lAvPWvaY',
      authDomain: 'fuelsqcv2-buc.firebaseapp.com',
      projectId: 'fuelsqcv2-buc'
    };
  }
  if (hostname.match(/colpipe./)) {
    return {
      apiKey: 'AIzaSyBCUyZhWwk-dt71rUm8Jgclrj3eYN5Bq3w',
      authDomain: 'fuelsqcv2-colonial.firebaseapp.com',
      projectId: 'fuelsqcv2-colonial'
    };
  }
  if (hostname.match(/ctl./)) {
    return {
      apiKey: 'AIzaSyA6KMvsgDRoR4pwLL9x4YTbtHcQgkysFHw',
      authDomain: 'fuelsqcv2-ctl.firebaseapp.com',
      projectId: 'fuelsqcv2-ctl'
    };
  }
  if (hostname.match(/cvr./)) {
    return {
      apiKey: 'AIzaSyAM71xW-n8blf6kGfsyUT-f29U2iX0huNM',
      authDomain: 'fuelsqcv2-cvr.firebaseapp.com',
      projectId: 'fuelsqcv2-cvr',
      logo: '/logo_cvr.svg'
    };
  }
  if (hostname.match(/demo./)) {
    return {
      apiKey: 'AIzaSyB69UE5Y1L7es8WxgtQfKGVVkrBA9Qiuic',
      authDomain: 'fuelsqcv2-demo.firebaseapp.com',
      projectId: 'fuelsqcv2-demo'
    };
  }
  if (hostname.match(/ggpetro./)) {
    return {
      apiKey: 'AIzaSyA9ltHOhmofIQm9VxWy67dqWZNeEuLcCY0',
      authDomain: 'fuelsqcv2-ggpetro.firebaseapp.com',
      projectId: 'fuelsqcv2-ggpetro'
    };
  }
  if (hostname.match(/hep./) || hostname.match(/hfsm./)) {
    return {
      apiKey: 'AIzaSyBiPQwBIMwAUbfIXE1fvMy_ppPCEHDDz2A',
      authDomain: 'fuelsqcv2-hep.firebaseapp.com',
      projectId: 'fuelsqcv2-hep'
    };
  }
  if (hostname.match(/hunt./)) {
    return {
      apiKey: 'AIzaSyAZjO4mH4b99J0K1Nn-yTpGMILia83hyW4',
      authDomain: 'fuelsqcv2-hunt.firebaseapp.com',
      projectId: 'fuelsqcv2-hunt'
    };
  }
  if (hostname.match(/mmp./)) {
    return {
      apiKey: 'AIzaSyCCfZkKy6ZKKoRrodrKusQk6dCAVCVm6Jc',
      authDomain: 'fuelsqcv2-mmp.firebaseapp.com',
      projectId: 'fuelsqcv2-mmp'
    };
  }
  if (hostname.match(/arteco./)) {
    return {
      apiKey: 'AIzaSyDv_UXQSC1-dggkpUYrk6E5BixVpqiXPak',
      authDomain: 'fuelsqcv2-arteco.firebaseapp.com',
      projectId: 'fuelsqcv2-arteco'
    };
  }
  if (hostname.match(/ser./)) {
    return {
      apiKey: 'AIzaSyCZEb9UmobWQtMpFfsbO8VkO5vOUydkvqs',
      authDomain: 'fuelsqcv2-ser.firebaseapp.com',
      projectId: 'fuelsqcv2-ser'
    };
  }
  if (hostname.match(/ies./)) {
    return {
      apiKey: 'AIzaSyCC__ybYQ_-9HZWLV9I0eMDyBSNJaTHxMg',
      authDomain: 'fuelsqcv2-ies.firebaseapp.com',
      projectId: 'fuelsqcv2-ies'
    };
  }
  if (hostname.match(/lupton./)) {
    return {
      apiKey: 'AIzaSyDLYNiAF2prm6SsWdcDtuw06oVhB4JaIto',
      authDomain: 'fuelsqcv2-lupton.firebaseapp.com',
      projectId: 'fuelsqcv2-lupton'
    };
  }
  if (hostname.match(/propet./)) {
    return {
      apiKey: 'AIzaSyB9CbfOwdv-p9BoDchga4cjahl01Mnveyo',
      authDomain: 'fuelsqcv2-propet.firebaseapp.com',
      projectId: 'fuelsqcv2-propet'
    };
  }

  return {
    apiKey: 'AIzaSyBPLKs0dJ2KVXfPncfxPo1_-xZtxKfsDNM',
    authDomain: 'fuelsqcv2-dev.firebaseapp.com',
    projectId: 'fuelsqcv2-dev',
    logo: '/ASTM-Insight-SQC.png'
  };
}

export function getAppConfig() {
  let app;
  if (getApps().length === 0) {
    const appConfig = getConfiguration(window.location.hostname);
    app = initializeApp(appConfig);
  } else {
    // Use existing app if already initialized
    app = getApp();
  }
  return app;
}

export function getCurrentFeatureFlags() {
  const currentProjectId = getAppConfig().options.projectId;
  let featureFlags = [];
  switch (currentProjectId) {
    case 'fuelsqcv2-hunt':
      featureFlags = ['lcl-data-entry-columns', 'newest-default-sort', 'config-download-button'];
      break;
    case 'fuelsqcv2-arteco':
      featureFlags = ['nonUs'];
      break;
    /* // for demoing non-US features in demo:
    case 'fuelsqcv2-demo':
      featureFlags = ['nonUs'];
      break;
    */
    default:
      break;
  }
  return featureFlags;
}

export const currentLogo = getAppConfig().options.logo
  ? getAppConfig().options.logo
  : '/ASTM-Insight-SQC.png';

const firebaseApp = getAppConfig();

export default firebaseApp;
