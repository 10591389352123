import React from 'react';
import CustomTable from '../../Table/CustomTable';
import { eventColumns } from './Columns';
import { useInventoryEquipments, useInventoryEvents, useInventorySettings } from '../../../util/db';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';
import moment from 'moment';

const EventAtGlance = () => {
  const { data: rawEvents = [] } = useInventoryEvents();
  const { data: instruments = [] } = useInventoryEquipments();
  const { data: inventorySettingsEventStatus = [] } = useInventorySettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: inventorySettingsEventType = [] } = useInventorySettings(SETTING_TYPE.EVENT_TYPE);
  const now = moment();
  const events = rawEvents.map((row) => {
    const dataInstrument = instruments.find((item) => item.id === row.instrumentName);
    const dataStatus = inventorySettingsEventStatus.find((item) => item.id === row.status);
    const dataType = inventorySettingsEventType.find((item) => item.id === row.eventType);
    return {
      ...row,
      location: dataInstrument?.location,
      instrumentId: dataInstrument?.id,
      instrumentName: dataInstrument?.name,
      status: dataStatus?.name,
      eventType: dataType?.name
    };
  });
  const pastDueRows = events.filter((event) => {
    return (
      event.dateDue.seconds * 1000 < now.startOf('day').valueOf() && event.status !== 'Complete'
    );
  });
  const inProgressRows = events.filter((event) => {
    return (
      event.dateStarted &&
      event.dateStarted.seconds * 1000 <= now.startOf('day').valueOf() &&
      event.dateDue.seconds * 1000 >= now.endOf('day').valueOf() &&
      event.status !== 'Complete'
    );
  });
  const next30DaysRows = events.filter((event) => {
    return (
      event.dateDue.seconds * 1000 >= now.startOf('day').valueOf() &&
      event.dateDue.seconds * 1000 <= moment().add(30, 'days').endOf('day').valueOf() &&
      event.status !== 'Complete'
    );
  });
  return (
    <>
      <h2>At a glance</h2>
      <div className="grid-view">
        <h3>Past Due</h3>
        <CustomTable numberOfRows={20} data={pastDueRows} header={eventColumns} />
        <h3>In Progress</h3>
        <CustomTable numberOfRows={20} data={inProgressRows} header={eventColumns} />
        <h3>Next 30 days</h3>
        <CustomTable numberOfRows={20} data={next30DaysRows} header={eventColumns} />
      </div>
    </>
  );
};

export default EventAtGlance;
