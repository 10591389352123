import React from 'react';
import PropTypes from 'prop-types';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

function SQCHistogram({ data }) {
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={data} margin={{ top: 5, right: 20, bottom: 15, left: 0 }}>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="bin" name="Bin" />
          <YAxis name="Count" />
          <Legend verticalAlign="top" height={36} />
          <Bar dataKey="count" name="Count" barSize={20} fill="#413ea0" />
          <Line
            type="monotone"
            name="Normal PDF"
            label="Normal PDF"
            dataKey="normalPDF"
            stroke="#ff7300"
          />
          <Tooltip />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}

SQCHistogram.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SQCHistogram;
