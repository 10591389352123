import { Button } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { VendorStatusOptions } from '../TabSettings/Columns';

const ModalFormVendor = ({ show, handleClose, handleSave, data }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      id: data?.id || '',
      vendorId: data?.vendorId || '',
      companyName: data?.companyName || '',
      department: data?.department || '',
      status: data?.status || '',
      contactName: data?.contactName || '',
      contactEmail: data?.contactEmail || '',
      contactPhone: data?.contactPhone || '',
      notes: data?.notes || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      vendorId: data?.vendorId || '',
      companyName: data?.companyName || '',
      department: data?.department || '',
      status: data?.status || '',
      contactName: data?.contactName || '',
      contactEmail: data?.contactEmail || '',
      contactPhone: data?.contactPhone || '',
      notes: data?.notes || ''
    });
  }, [show]);

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(handleSave)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Vendor' : 'Add Vendor'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Vendor ID</Form.Label>
                <Form.Control name="vendorId" {...register('vendorId', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Company Name</Form.Label>
                <Form.Control name="companyName" {...register('companyName', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Department</Form.Label>
                <Form.Control name="department" {...register('department', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Status</Form.Label>
                <Form.Select name="status" {...register('status', { required: true })}>
                  <option value="">-- Select --</option>
                  {VendorStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <Form.Control name="contactName" {...register('contactName', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="contactEmail"
                  {...register('contactEmail', { required: true })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  name="contactPhone"
                  {...register('contactPhone', { required: true })}
                />
              </Form.Group>
              <Form.Group as={Col}></Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  {...register('notes', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormVendor;
