import { Button } from '@mui/material';
import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';

const ModalAddPerson = ({ show, handleClose, handleSave }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      contact: '',
      email: '',
      phone: ''
    }
  });

  useEffect(() => {
    reset({
      contact: '',
      email: '',
      phone: ''
    });
  }, [show]);
  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(handleSave)} onReset={handleClose}>
          <Modal.Header closeButton>Add Person</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Contact</Form.Label>
                <Form.Control name="contact" {...register('contact', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control name="email" {...register('email', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Phone</Form.Label>
                <Form.Control name="phone" {...register('phone', { required: true })} />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalAddPerson;
