import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  YAxis,
  XAxis
} from 'recharts';
import Form from 'react-bootstrap/Form';
import ChartContext from './DataEntrySection/DataEntryProvider';

const uniq = require('lodash/uniq');

function SQCInstrumentsChart({
  instrumentsArray,
  instrumentsDots,
  data,
  syncId,
  xAxis,
  yMin,
  yMax,
  lineType
}) {
  const [instruments, setInstruments] = useState([]);
  const [currentInstrumentsArray, setCurrentInstrumentsArray] = useState(instrumentsArray);
  const { currentControlStrategyConfigurations } = useContext(ChartContext);
  const [instrumentData] = useState(data);

  const formatNothing = () => {
    return '';
  };

  const formatXAxis = (tickItem) => {
    return String(data[tickItem]?.date) || '';
  };

  const formatYAxis = (tickItem) => {
    return tickItem.toPrecision(currentControlStrategyConfigurations.chartSigDigits);
  };

  const CustomizedDotInstrument = ({ cx, cy, payload }) => {
    const dotToReturn = [];
    if (cy !== null) {
      const currentInstrument = instrumentsDots.find(
        (instrument) => instrument.name === payload.instrument
      );
      const currentIndex = currentInstrumentsArray.findIndex(
        (instrument) => instrument.name === currentInstrument.name
      );
      if (currentInstrumentsArray[currentIndex].checked) {
        dotToReturn.push(
          <svg
            x={cx - 3}
            y={cy - 3}
            width={15}
            height={15}
            key={uuidv4()}
            fill={currentInstrument.color}
            viewBox="0 0 1024 1024"
          >
            <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
          </svg>
        );
      }
    }
    return dotToReturn;
  };

  const handleCheckboxClick = (e) => {
    const updatedInstrumentsArray = currentInstrumentsArray.map((instrument) => {
      if (instrument.name === e.target.id) {
        return {
          ...instrument,
          checked: e.target.checked
        };
      }
      return instrument;
    });
    setCurrentInstrumentsArray(updatedInstrumentsArray);
    if (e.target.checked) {
      setInstruments([...instruments, e.target.id]);
    } else {
      setInstruments([...instruments].filter((instrument) => instrument !== e.target.id));
    }
  };

  function renderCurrentInstrumentCheckboxes() {
    const checkboxesToReturn = [];
    currentInstrumentsArray.forEach((instrument, index) => {
      checkboxesToReturn.push(
        <div key={uuidv4()} className="checkbox-buttons">
          <div
            style={{
              marginTop: '5%',
              height: '13px',
              width: '13px',
              backgroundColor: currentInstrumentsArray[index].color,
              borderRadius: '100%',
              marginRight: '4px'
            }}
          />
          {instrument.name}
          <Form.Check
            checked={currentInstrumentsArray[index].checked}
            onChange={handleCheckboxClick}
            id={instrument.name}
          />
        </div>
      );
    });
    return checkboxesToReturn;
  }

  useEffect(() => {
    if (Array.isArray(instrumentData)) {
      let currentInstruments = [];
      let sortedInstrumentChartData;
      if (data) {
        sortedInstrumentChartData = data;
      } else {
        sortedInstrumentChartData = instrumentData;
      }

      for (const dataPoint of sortedInstrumentChartData) {
        dataPoint[dataPoint.instrument] = dataPoint.result;
        currentInstruments.push(dataPoint.instrument);
      }
      currentInstruments = uniq(currentInstruments);
      setInstruments(currentInstruments);
    }
  }, [instrumentData]);

  const renderInstrumentLines = (currentInstruments) => {
    const linesToReturn = [];
    const updatedInstruments = currentInstruments;
    updatedInstruments.forEach((instrument) => {
      const currentInstrument = currentInstrumentsArray.find(
        (instrumentToFind) => instrument === instrumentToFind.name
      );
      const currentColor = currentInstrument.color;
      linesToReturn.push(
        <Line
          connectNulls
          name={instrument}
          type={lineType}
          dataKey={instrument}
          stroke={currentColor}
          fill={currentColor}
          isAnimationActive={false}
          dot={<CustomizedDotInstrument key={uuidv4()} instrumentsDots={instrumentsDots} />}
        />
      );
    });
    return linesToReturn;
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const description = `${payload[0].dataKey}: ${payload[0].value}`;
      const dateTime = `${payload[0].payload.date}`;
      const color = `${payload[0].color}`;
      return (
        <div className="custom-tooltip">
          <p>{label}</p>
          <p
            style={{
              color: color
            }}
          >
            {description}
          </p>
          <p>{dateTime}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="checkbox-buttons-container">{renderCurrentInstrumentCheckboxes()}</div>
      <ResponsiveContainer key={uuidv4()} height={400}>
        <LineChart
          data={instrumentData}
          key={uuidv4()}
          syncId={syncId}
          margin={{
            top: 5,
            right: 20,
            bottom: 15,
            left: 5
          }}
        >
          {renderInstrumentLines(instruments)}
          <CartesianGrid stroke="#ccc" key={uuidv4()} strokeDasharray="5 5" />
          <XAxis tickFormatter={formatXAxis} />
          <YAxis type="number" key={uuidv4()} domain={[yMin, yMax]} tickFormatter={formatYAxis} />
          <Brush dataKey={xAxis} key={uuidv4()} tickFormatter={formatNothing} />
          <Legend verticalAlign="top" key={uuidv4()} height={36} />
          <Tooltip content={<CustomTooltip />} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

SQCInstrumentsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  syncId: PropTypes.string.isRequired,
  xAxis: PropTypes.string.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired,
  instrumentsArray: PropTypes.array.isRequired,
  instrumentsDots: PropTypes.array.isRequired,
  lineType: PropTypes.string
};

SQCInstrumentsChart.defaultProps = {
  lineType: 'monotone'
};

export default SQCInstrumentsChart;
