import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { settingDocumentColumns } from './Columns';
import {
  deleteInventoryDocuments,
  setInventoryDocuments,
  useGetCompanies,
  useInventoryDocuments,
  useInventoryEquipments,
  useInventorySettings
} from '../../../util/db';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { SETTING_TYPE } from './DefineCategories';
import { v4 as uuidv4 } from 'uuid';

const DocumentList = () => {
  const [filterModel, setFilterModel] = useState({});
  const { data: instruments = [] } = useInventoryEquipments();
  const { data: documents = [] } = useInventoryDocuments(filterModel);
  const { data: companies } = useGetCompanies();
  const { data: inventorySettingsDocumentType = [] } = useInventorySettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const [allLocations, setAllLocations] = useState([]);
  const [editingDocument, setEditingDocument] = useState(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      const updatedLocationsPersonnel = [];
      companies.forEach((company) => {
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
        const locationToPush = {
          name: currentLocation,
          id: currentLocation,
          value: currentLocation,
          label: currentLocation
        };
        const locationExists = updatedLocationsPersonnel.find(
          (location) => location.name === currentLocation
        );
        if (!locationExists) {
          updatedLocationsPersonnel.push(locationToPush);
        }
      });
      setAllLocations(updatedLocationsPersonnel);
    }
  }, [companies]);

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpen(true);
  };

  const handleSave = async (data) => {
    await setInventoryDocuments(data);
    setOpen(false);
  };

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const handleInstrumentChange = (event) => {
    if (event.target.value) {
      const index = instruments.findIndex((i) => i.id === event.target.value);
      setFilterModel({
        ...filterModel,
        documents: instruments[index].documents ?? [uuidv4()] // trick to avoid the error "query Invalid Query. A non-empty array is required for 'in' filters"
      });
    } else {
      setFilterModel({
        ...filterModel,
        documents: undefined
      });
    }
  };

  const handleDelete = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      await deleteInventoryDocuments(data);
    }
  };

  return (
    <>
      <div className="filter">
        <p>
          <strong>Filter documents by:</strong>
        </p>
        <div>
          <Row>
            <Form.Group as={Col} controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Select onChange={(event) => handleChange('location', event)}>
                <option value="">All locations</option>
                {allLocations.map((option) => (
                  <option key={option.name} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="documentType">
              <Form.Label>Document Type</Form.Label>
              <Form.Select onChange={(event) => handleChange('documentType', event)}>
                <option value="">All types</option>
                {inventorySettingsDocumentType.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="instrument">
              <Form.Label>Instrument</Form.Label>
              <Form.Select onChange={(event) => handleInstrumentChange(event)}>
                <option value="">All Instrument</option>
                {instruments.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}></Form.Group>
          </Row>
        </div>
      </div>
      <div className="grid-view">
        <div className="inner-actions">
          <h2>Documents</h2>
          <Button variant="contained" onClick={() => showFormDocument(null)}>
            Add new
          </Button>
          <ModalFormDocument
            show={isOpen}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
            data={editingDocument}
            allLocations={allLocations}
            inventorySettingsDocumentType={inventorySettingsDocumentType}
          />
        </div>
        <CustomTable
          numberOfRows={20}
          data={documents}
          header={settingDocumentColumns({
            documentTypes: inventorySettingsDocumentType,
            actionEditDocument: showFormDocument,
            instruments
          })}
          deleteRowData={handleDelete}
          action={['delete']}
          sort={{ sorting: { sortModel: [{ field: 'documentId', sort: 'asc' }] } }}
          rowHeight="auto"
          sx={{
            '.MuiDataGrid-cell': {
              minHeight: '52px !important'
            },
            '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
              display: 'none'
            }
          }}
        />
      </div>
    </>
  );
};

export default DocumentList;
