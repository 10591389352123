import moment from 'moment';
import { Link } from '../../../util/router';
import { Button } from '@mui/material';

export const dayTimeComparator = (v1, v2) => {
  const d1 = new Date(v1).getTime();
  const d2 = new Date(v2).getTime();
  return d1 - d2;
};

export const VendorStatusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' }
];

export const VendorStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const AssiciatedEventStatus = {
  PAST_DUE: 'Past Due',
  IN_PROGRESS: 'In Progress'
};

export const DocumentType = {
  WORK_ORDER: 'Work Order',
  MAINTENANCE_RECORD: 'Maintenance Record',
  CALIBRATION_RECORD: 'Calibration Record'
};

export const listVendorColumns = [
  {
    field: 'vendorId',
    headerName: 'ID',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/inventory/settings/vendors/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'department',
    headerName: 'Department',
    flex: 1,
    minWidth: 180
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      return VendorStatus[params.value];
    }
  },
  {
    field: 'contactName',
    headerName: 'Primary Contact',
    flex: 1,
    minWidth: 180
  },
  {
    field: 'contactEmail',
    headerName: 'Primary Email',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'contactPhone',
    headerName: 'Primary Phone',
    flex: 1,
    minWidth: 150
  }
];

export const documentColumns = [
  {
    field: 'documentId',
    headerName: 'ID',
    flex: 2
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 4
  }
];

export const assiciatedInstrumentColumns = ({ instrumentStatus }) => [
  {
    field: 'name',
    headerName: 'Instrument Name',
    flex: 2,
    renderCell: (params) => {
      return <Link to={`/inventory/instruments/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'serialNumber',
    headerName: 'Serial Number',
    flex: 2
  },
  {
    field: 'model',
    headerName: 'Model',
    flex: 2
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    renderCell: (params) => {
      const status = instrumentStatus.find((item) => item.id === params.value);
      if (status) {
        return status.name;
      } else {
        return 'Undefined';
      }
    }
  },
  {
    field: 'onLoan',
    headerName: 'On Loan',
    flex: 2,
    renderCell: (params) => {
      return params.value ? 'Y' : 'N';
    }
  },
  {
    field: 'dateStarted',
    headerName: 'Next event date',
    sortComparator: dayTimeComparator,
    flex: 2,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  }
];

export const assiciatedEventColumns = ({ instruments, eventStatus }) => [
  {
    field: 'eventNumber',
    headerName: 'ID',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/inventory/events/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'instrumentName',
    headerName: 'Instrument Name',
    flex: 2,
    renderCell: (params) => {
      const data = instruments.find((item) => item.id === params.value);
      return data ? <Link to={`/inventory/instruments/${data.id}`}>{data.name}</Link> : '';
    }
  },
  {
    field: 'dateDue',
    headerName: 'Date Due',
    sortComparator: dayTimeComparator,
    flex: 2,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 2
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    renderCell: (params) => {
      const status = eventStatus.find((item) => item.id === params.value);
      if (status) {
        return status.name;
      } else {
        return 'Undefined';
      }
    }
  }
];

export const settingDocumentColumns = ({
  documentTypes,
  actionEditDocument = () => {},
  instruments = []
}) => [
  {
    field: 'documentId',
    headerName: 'Document ID',
    width: 140,
    renderCell: (params) => {
      return <Button onClick={() => actionEditDocument(params.row)}>{params.value}</Button>;
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 300,
    flex: 1
  },
  {
    field: 'instrumentName',
    headerName: 'Instrument Name',
    minWidth: 180,
    flex: 1,
    renderCell: (params) => {
      return (
        <div className="comments">
          {instruments
            .filter((item) => (item.documents || []).includes(params.row.id))
            .map((item, index) => (
              <p key={index}>
                <Link to={`/inventory/instruments/${item.id}`}>{item.name}</Link>
              </p>
            ))}
        </div>
      );
    }
  },
  {
    field: 'createdAt',
    headerName: 'Date Created',
    sortComparator: dayTimeComparator,
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'documentType',
    headerName: 'Document Type',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      const dType = documentTypes.find((item) => item.id === params.value);
      if (dType) {
        return dType.name;
      } else {
        return 'Undefined';
      }
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 300,
    flex: 1
  }
];

export const instrumentStatusColumns = [
  {
    field: 'name',
    headerName: 'Instrument Status',
    minWidth: 150,
    flex: 1
  }
];

export const eventStatusColumns = [
  {
    field: 'name',
    headerName: 'Event Status',
    minWidth: 150,
    flex: 1
  }
];

export const eventTypeColumns = [
  {
    field: 'name',
    headerName: 'Event Type',
    minWidth: 150,
    flex: 1
  }
];

export const documentTypeColumns = [
  {
    field: 'name',
    headerName: 'DocumentType',
    minWidth: 150,
    flex: 1
  }
];
