import moment from 'moment';
import { Link } from '../../../util/router';

export const dayTimeComparator = (v1, v2) => {
  const d1 = new Date(v1).getTime();
  const d2 = new Date(v2).getTime();
  return d1 - d2;
};

export const InstrumentStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const listInstrumentColumns = [
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 300,
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Instrument Name',
    minWidth: 180,
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/inventory/instruments/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'serialNumber',
    headerName: 'Serial Number',
    minWidth: 150,
    flex: 1,
    sortable: false
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    minWidth: 130,
    flex: 1
  },
  {
    field: 'model',
    headerName: 'Model',
    minWidth: 150,
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1
  },
  {
    field: 'onLoan',
    headerName: 'On Loan',
    minWidth: 110,
    flex: 1,
    valueGetter: (params) => {
      return params.value ? 'Y' : 'N';
    }
  },
  {
    field: 'nextEventDate',
    headerName: 'Next event date',
    sortComparator: dayTimeComparator,
    minWidth: 180,
    flex: 1,
    valueGetter: (params) => {
      return params.value ? moment(params.value).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'notes',
    headerName: 'Notes',
    minWidth: 300,
    flex: 1,
    renderCell: (params) => {
      return (
        <div className="comments">
          {(params.value?.split('\n') || []).map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      );
    }
  }
];

export const documentColumns = [
  {
    field: 'documentId',
    headerName: 'ID',
    flex: 2
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 4
  }
];

export const vendorColumns = [
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 2
  },
  {
    field: 'contactName',
    headerName: 'Contact',
    flex: 2
  },
  {
    field: 'contactEmail',
    headerName: 'Email',
    flex: 2
  },
  {
    field: 'contactPhone',
    headerName: 'Phone',
    flex: 2
  }
];

export const instrumentEventColumns = ({ instrumentName }) => [
  {
    field: 'eventNumber',
    headerName: 'ID',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/inventory/events/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'instrument',
    headerName: 'Instrument Name',
    flex: 1,
    minWidth: 150,
    renderCell: () => {
      return instrumentName;
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'responsibleParty',
    headerName: 'Event Owner',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'dateDue',
    headerName: 'Date Due',
    sortComparator: dayTimeComparator,
    flex: 1,
    minWidth: 150,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  }
];

export const eventColumns = [
  {
    field: 'location',
    headerName: 'Location',
    flex: 1,
    minWidth: 120
  },
  {
    field: 'instrument',
    headerName: 'Instrument Name',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'type',
    headerName: 'Event Type',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'dateStarted',
    headerName: 'Date Started',
    sortComparator: dayTimeComparator,
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => {
      return params.value ? moment(params.value).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'dateDue',
    headerName: 'Date Due',
    sortComparator: dayTimeComparator,
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => {
      return params.value ? moment(params.value).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'nextEvent',
    headerName: 'Next Event',
    sortComparator: dayTimeComparator,
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => {
      return params.value ? moment(params.value).format('MM/DD/YYYY') : '';
    }
  }
];
