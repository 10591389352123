import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import './AssessmentsSection.scss';
import { v4 as uuidv4 } from 'uuid';
import SQCLineChart from '../SQCLineChart';
import SQCMovingRangeChart from '../SQCMovingRangeChart';
import SQCScatterPlot from '../SQCScatterPlot';
import SQCHistogram from '../SQCHistogram';
import ChartContext from '../DataEntrySection/DataEntryProvider';
import { updateData, useEntitiesByFullKey, useFindAssessmentsByShortKey } from '../../util/db';
import { defaultColumnsReport, summaryStatisticsColumnsPeriodic } from '../Columns';
import { currentLogo } from '../../util/firebase';
import CustomTable from '../Table/CustomTable';
import moment from 'moment';

export default function AssessmentsSection({
  chartData,
  chartScalars,
  currentAssessments,
  currentInstrument,
  currentMethod,
  currentShortKey,
  currentFullKey,
  filteredChartHits,
  summaryStatisticsAssessmentColumns,
  unit
}) {
  const [showModal, setShowModal] = useState(false);
  const [showPeriodicModal, setShowPeriodicModal] = useState(false);
  const { data: assessments } = useFindAssessmentsByShortKey(currentShortKey);
  const { data: dataPoints } = useEntitiesByFullKey(currentFullKey);
  const [periodicPage, setPeriodicPage] = useState('1');
  // eslint-disable-next-line no-unused-vars
  const [currentResultsData, setCurrentResultsData] = useState([]);
  const [modalAssessment, setModalAssessment] = useState({});
  const {
    resultsDataTableData,
    currentControlStrategyConfigurations,
    currentSampleName,
    currentParameter
  } = useContext(ChartContext);
  // eslint-disable-next-line no-unused-vars
  const [currentAssessmentTrending, setCurrentAssessmentTrending] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentAssessmentCyclicalPatterns, setCurrentAssessmentCyclicalPatterns] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentAssessmentTpiAssessment, setCurrentAssessmentTpiAssessment] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentAssessmentComments, setCurrentAssessmentComments] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [currentPooling, setCurrentPooling] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [currentDataPoints, setCurrentDataPoints] = useState([]);

  const handleCommentChange = (e) => {
    setCurrentAssessmentComments(e.target.value);
  };

  const selectPeriodicPage = (e) => {
    setPeriodicPage(e.target.value);
  };

  const archiveDataPoints = () => {
    dataPoints.forEach((dataPointIn) => {
      const dataPoint = dataPointIn;
      dataPoint.flags = '';
      dataPoint.locked = true;
      updateData(dataPoint.id, dataPoint);
    });
  };

  const unArchiveDataPoints = () => {
    dataPoints.forEach((dataPointIn) => {
      const dataPoint = dataPointIn;
      dataPoint.flags = '';
      dataPoint.locked = false;
      updateData(dataPoint.id, dataPoint);
    });
  };

  useEffect(() => {
    if (Array.isArray(assessments) && assessments.length > 0) {
      setCurrentResultsData(assessments);
    }
  }, [assessments]);

  useEffect(() => {
    if (Array.isArray(dataPoints) && dataPoints.length > 0) {
      setCurrentDataPoints(dataPoints);
    }
  }, [dataPoints]);

  const assessmentColumns = [
    {
      field: 'created',
      headerName: 'Completed On',
      flex: 1,
      sortable: false,
      editable: false
    },
    {
      field: 'createdBy',
      headerName: 'Completed By',
      flex: 1,
      sortable: false,
      editable: false
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      sortable: false,
      editable: false
    },
    {
      field: 'fullKey',
      headerName: 'Method/Parameter',
      flex: 1,
      sortable: false,
      editable: false,
      valueGetter: (params) => {
        const decomposeKey = (params.value || params.row.shortKey).split('|');
        return `${decomposeKey[1]}${decomposeKey[4] ? `/${decomposeKey[4]}` : ''}`;
      }
    },
    {
      field: 'outcome',
      headerName: 'Outcome',
      flex: 1,
      sortable: false,
      editable: false
    },
    {
      field: 'delete',
      headerName: 'View',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <div>
            <button
              onClick={() => {
                setModalAssessment(row);
                if (row.type === 'Initial') {
                  setShowModal(true);
                } else if (row.type === 'Periodic') {
                  setShowPeriodicModal(true);
                }
              }}
              type="submit"
              style={{ border: 'none' }}
            >
              View
            </button>
          </div>
        );
      }
    }
  ];

  const pageSizeDefault = 20;

  const options = {
    sizePerPage: 20,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const cellEditProp = {
    blurToSave: true
  };

  const renderPeriodicPage = (page) => {
    switch (page) {
      case '1':
        return (
          <>
            <Row className="report-header">
              <h4>
                Summary Statistics: {currentParameter}, {unit}
              </h4>
            </Row>
            <Row className="statistics-table">
              <CustomTable
                hideFooter={true}
                data={modalAssessment.summaryStatistics}
                header={summaryStatisticsColumnsPeriodic}
              />
            </Row>
            <div>
              <table>
                <tr />
              </table>
            </div>
            <Row className="report-header">
              <h4>
                Assessment:
                {currentParameter}
              </h4>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  1. Visual Assessment for suspicious results (including GESD outliers)
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioSR-pass"
                  id="radioSR-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.suspiciousResults === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.suspiciousResults === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioSR-fail"
                  id="radioSR-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.suspiciousResults === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.suspiciousResults === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  2. Visual Assessment for unusual patterns (trending, clustering, cycles)
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioUP-pass"
                  id="radioUP-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.unusualPatterns === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.unusualPatterns === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioUP-fail"
                  id="radioUP-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.unusualPatterns === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.unusualPatterns === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  3. Evaluation of TPI to assess site precision vs Reproducibility
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioTE-pass"
                  id="radioTE-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tpiEvaluation === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.tpiEvaluation === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioTE-fail"
                  id="radioTE-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tpiEvaluation === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.tpiEvaluation === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  4. Performance of Chi Square test to assess site precision vs Reproducibility
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioCST-pass"
                  id="radioCST-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.chiSquareTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.chiSquareTest === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioCST-fail"
                  id="radioCST-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.chiSquareTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.chiSquareTest === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  5. Assessment of normal probability plot (for linearity) and histogram
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioNPP-pass"
                  id="radioNPP-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.normalProbabilityPlot === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.normalProbabilityPlot === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioNPP-fail"
                  id="radioNPP-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.normalProbabilityPlot === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.normalProbabilityPlot === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  6. Evaluation of Anderson-Darling test statistic for normality
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioAD-pass"
                  id="radioAD-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.andersonDarling === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.andersonDarling === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioAD-fail"
                  id="radioAD-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.andersonDarling === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.andersonDarling === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  7. Performance of t-Test to evaluate accuracy / bias
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radiotT-pass"
                  id="radiotT-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.tTest === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radiotT-fail"
                  id="radiotT-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.tTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.tTest === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  8. Performance of F-Test to evaluate changes in variance
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radiofT-pass"
                  id="radiofT-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.fTest === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.fTest === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radiofT-fail"
                  id="radiofT-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.fTest === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.fTest === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  Assessor comments
                </h6>
              </Col>
            </Row>
            <Row>
              <Col lg={9}>
                <Form className="ia-table" style={{ marginLeft: '50px' }}>
                  <Form.Group>
                    <Form.Control
                      className="comments"
                      as="textarea"
                      rows={2}
                      aria-label="Basic example"
                      value={modalAssessment.assessorComments}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col lg={9}>
                <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                  Assessment outcome
                </h6>
              </Col>
              <Col>
                <ToggleButton
                  key="radioOutcome-pass"
                  id="radioOutcome-pass"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.outcome === 'Pass'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Pass"
                  checked={modalAssessment.outcome === 'Pass'}
                >
                  Pass
                </ToggleButton>
                <ToggleButton
                  key="radioOutcome-fail"
                  id="radioOutcome-fail"
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  style={
                    modalAssessment.outcome === 'Fail'
                      ? { backgroundColor: 'navy', color: 'white' }
                      : {}
                  }
                  value="Fail"
                  checked={modalAssessment.outcome === 'Fail'}
                >
                  Fail
                </ToggleButton>
              </Col>
            </Row>
          </>
        );
      case '2':
        return (
          <>
            <Row className="report-header">
              <h4>
                Chart:
                {currentParameter}
              </h4>
            </Row>
            <div>
              <div className="chart-row-container">
                <SQCLineChart
                  xAxis="updatedIndex"
                  syncId="chained"
                  yMin={chartScalars.cYMin}
                  yMax={chartScalars.cYMax}
                  data={chartData}
                  lines={[
                    {
                      yAxis: 'resultOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'ewmaOmits',
                      name: 'Trend',
                      color: '#B0E0E6'
                    },
                    {
                      yAxis: 'mean',
                      name: 'Average',
                      reference: chartData.length > 20,
                      label: `Average: ${chartData[chartData.length - 1].mean.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd15p',
                      label: `-1.5 Sigma: ${chartData[chartData.length - 1].sd15p.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#9932CC',
                      reference: true
                    },
                    {
                      yAxis: 'sd15n',
                      label: `+1.5 Sigma: ${chartData[chartData.length - 1].sd15n.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#9932CC',
                      reference: true
                    },
                    {
                      yAxis: 'sd3n',
                      label: `LCL: ${chartData[chartData.length - 1].sd3n.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#FBCEB1',
                      reference: true
                    },
                    {
                      yAxis: 'sd3p',
                      label: `UCL: ${chartData[chartData.length - 1].sd3p.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
                <SQCMovingRangeChart
                  xAxis="updatedIndex"
                  key={uuidv4()}
                  data={chartData}
                  syncId="chained2"
                  yMin={0}
                  yMax={chartScalars.mrYMax}
                  lines={[
                    {
                      yAxis: 'mrOmits',
                      name: 'Data',
                      color: '#32CD32'
                    },
                    {
                      yAxis: 'mean',
                      overrideValue: chartScalars.mrMean,
                      name: 'Average',
                      reference: true,
                      label: `Average: ${chartScalars.mrMean.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#FFBF00'
                    },
                    {
                      yAxis: 'sd3p',
                      overrideValue: chartScalars.mrUCL,
                      label: `UCL: ${chartScalars.mrUCL.toPrecision(
                        currentControlStrategyConfigurations.chartSigDigits
                      )}`,
                      color: '#FBCEB1',
                      reference: true
                    }
                  ]}
                />
              </div>
              <Row>
                <Col>
                  <>
                    {chartScalars.histogramData && (
                      <SQCHistogram data={chartScalars.histogramData} />
                    )}
                    {!chartScalars.histogramData && <div>No Histogram</div>}
                  </>
                </Col>
                <Col>
                  <SQCScatterPlot
                    xAxis="zScoreOmits"
                    key={uuidv4()}
                    data={chartData}
                    syncId="chained2"
                    xMin={chartScalars.qqXMin}
                    xMax={chartScalars.qqXMax}
                    yMin={chartScalars.qqYMin}
                    yMax={chartScalars.qqYMax}
                    collections={[
                      {
                        name: 'Lab Results',
                        color: '#32CD32',
                        data: chartData.map((cd) => {
                          return {
                            x: cd.zScoreOmits,
                            y: cd.resultOmits
                          };
                        })
                      },
                      {
                        name: 'Trend Line',
                        color: '#FFBF00',
                        data: chartScalars.trendData,
                        line: true
                      }
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </>
        );
      case '3':
        return (
          <>
            <Row className="report-header">
              <h4>
                Data:
                {currentParameter}
              </h4>
            </Row>
            <div className="page-three-container">
              <CustomTable
                numberOfRows={pageSizeDefault}
                data={filteredChartHits}
                header={defaultColumnsReport}
                sort={{ sorting: { sortModel: [{ field: 'dateTime', sort: 'asc' }] } }}
              />
            </div>
          </>
        );
      default:
        return <div>Invalid Report Page</div>;
    }
  };

  return (
    <>
      <div className="assessments-container">
        <div>
          <h3>Assessments</h3>
        </div>
        <div className="assessments-table-container">
          <CustomTable
            numberOfRows={pageSizeDefault}
            header={assessmentColumns}
            data={currentAssessments}
          />
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Chart Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-center">
              <img alt="logo" src={currentLogo} width="200" />
            </Col>
            <Col className="col-center">
              <h3>ASTM SQC Insights</h3>
            </Col>
          </Row>
          <Row className="report-header">
            <h4>Initial Assessment Report</h4>
          </Row>
          <Row className="ia-table" style={{ paddingLeft: '32px' }}>
            <Col>
              <Row>
                <Col>Method:</Col>
                <Col>{currentMethod}</Col>
              </Row>
              <Row>
                <Col>Instrument:</Col>
                <Col>{currentInstrument}</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>Date:</Col>
                <Col>{moment(modalAssessment.created).format('MM/DD/YYYY')}</Col>
              </Row>
              <Row>
                <Col>Assessment by:</Col>
                <Col>{modalAssessment?.createdBy}</Col>
              </Row>
            </Col>
          </Row>
          <Row className="report-header">
            <h4>Summary Statistics</h4>
          </Row>
          <Row className="statistics-table">
            <CustomTable
              hideFooter={true}
              data={modalAssessment.summaryStatistics}
              header={summaryStatisticsAssessmentColumns}
            />
          </Row>
          <Row className="report-header">
            <h4>Assessment</h4>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                1. Is any trending in an upward or downward direction evident?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioTrending-pass"
                id="radioTrending-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                value="Pass"
                style={
                  modalAssessment.trendingDirectionEvident === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                checked={modalAssessment.trendingDirectionEvident === 'Pass'}
                onChange={() => setCurrentAssessmentTrending('Pass')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioTrending-fail"
                id="radioTrending-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                value="Fail"
                style={
                  modalAssessment.trendingDirectionEvident === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                checked={modalAssessment.trendingDirectionEvident === 'Fail'}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                2. Is any clustering evident?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioClusteringAP-pass"
                id="radioClusteringAP-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.clusteringEvident === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment.clusteringEvident === 'Pass'}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioClusteringAP-fail"
                id="radioClusteringAP-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.clusteringEvident === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment.clusteringEvident === 'Fail'}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                3. Are any cyclical patterns evident?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioCyclical-pass"
                id="radioCyclical-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.cyclicalPatterns === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment.cyclicalPatterns === 'Pass'}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioCyclical-fail"
                id="radioCyclical-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.cyclicalPatterns === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment.cyclicalPatterns === 'Fail'}
                onChange={(e) => setCurrentAssessmentCyclicalPatterns(e.currentTarget.value)}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                4. Is the data normal or approximately normal in distribution?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioDataNormal-pass"
                id="radioDataNormal-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.normalDistribution === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment.normalDistribution === 'Pass'}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioDataNormal-fail"
                id="radioDataNormal-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.normalDistribution === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment.normalDistribution === 'Fail'}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                5. TPI Assessment
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioTpi-pass"
                id="radioTpi-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.tpiAssessment === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment.tpiAssessment === 'Pass'}
                onChange={(e) => setCurrentAssessmentTpiAssessment(e.currentTarget.value)}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioTpi-fail"
                id="radioTpi-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.tpiAssessment === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment.tpiAssessment === 'Fail'}
                onChange={(e) => setCurrentAssessmentTpiAssessment(e.currentTarget.value)}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                6. Assessor comments
              </h6>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Form>
                <Form.Group>
                  <Form.Control
                    className="comments"
                    as="textarea"
                    rows={2}
                    onChange={handleCommentChange}
                    aria-label="Basic example"
                    value={modalAssessment.assessorComments}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                7. Assessment outcome
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioOutcome-pass"
                id="radioOutcome-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.outcome === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment.outcome === 'Pass'}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioOutcome-fail"
                id="radioOutcome-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment.outcome === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment.outcome === 'Fail'}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal size="xl" show={showPeriodicModal} onHide={() => setShowPeriodicModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Chart Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <img alt="logo" src={currentLogo} width="200" />
            </Col>
            <Col style={{ textAlign: 'center' }}>
              <h3>ASTM SQC Insights</h3>
            </Col>
            <Col>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                <div>Page</div>
                <Form.Select
                  style={{ width: '70px', marginLeft: '10px' }}
                  value={periodicPage}
                  onChange={selectPeriodicPage}
                  aria-label="Default select example"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
          <Row className="report-header">
            <h4>Periodic Assessment Report</h4>
          </Row>
          <Row className="ia-table" style={{ paddingLeft: '32px' }}>
            <Col>
              <Row>
                <Col>Method:</Col>
                <Col>{currentMethod}</Col>
              </Row>
              <Row>
                <Col>Instrument:</Col>
                <Col>{currentInstrument}</Col>
              </Row>
              <Row>
                <Col>Sample Name:</Col>
                <Col>{currentSampleName}</Col>
              </Row>
              <Row>
                <Col>Units:</Col>
                <Col>{unit}</Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col>Parameter:</Col>
                <Col>{modalAssessment?.fullKey?.split('|')[4]}</Col>
              </Row>
              <Row>
                <Col>Date:</Col>
                <Col>{moment(modalAssessment.created).format('MM/DD/YYYY')}</Col>
              </Row>
              <Row>
                <Col>Assessment by:</Col>
                <Col>{modalAssessment?.createdBy}</Col>
              </Row>
              <Row>
                <Col>Pre-treat:</Col>
                <Col>{currentControlStrategyConfigurations.pretreatData}</Col>
              </Row>
            </Col>
          </Row>
          <div className="periodic-buttons-container">
            <ToggleButton
              key="radioPooling-pass"
              id="radioPooling-pass"
              type="radio"
              variant="outline-primary"
              name="radio"
              value
              style={
                modalAssessment.pool === 'true' ? { backgroundColor: 'navy', color: 'white' } : {}
              }
              checked={modalAssessment.pool === 'true'}
            >
              Pool
            </ToggleButton>
            <ToggleButton
              key="radioPooling-fail"
              id="radioPooling-fail"
              type="radio"
              variant="outline-primary"
              name="radio"
              value={false}
              style={
                modalAssessment.pool === 'false' ? { backgroundColor: 'navy', color: 'white' } : {}
              }
              checked={modalAssessment.pool === 'false'}
            >
              Do Not Pool
            </ToggleButton>
            <Button onClick={archiveDataPoints}>Archive / Restart</Button>
            <Button onClick={unArchiveDataPoints}>unArchive</Button>
          </div>
          {renderPeriodicPage(periodicPage)}
        </Modal.Body>
      </Modal>
    </>
  );
}

AssessmentsSection.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartScalars: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentAssessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentInstrument: PropTypes.string.isRequired,
  currentMethod: PropTypes.string.isRequired,
  currentShortKey: PropTypes.string.isRequired,
  currentFullKey: PropTypes.string.isRequired,
  filteredChartHits: PropTypes.arrayOf(PropTypes.object).isRequired,
  summaryStatisticsAssessmentColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};
