import React, { useState } from 'react';
import './index.scss';
import { Link, Route, Switch, useRouteMatch, useLocation } from '../../util/router';
import { Box, Button } from '@mui/material';
import TabEvents from './TabEvents';
import EventDetails from './TabEvents/EventDetails';
import EventAtGlance from './TabEvents/EventAtGlance';
import TabInstruments from './TabInstruments';
import InstrumentDetails from './TabInstruments/InstrumentDetails';
import TabSettings from './TabSettings';

const CustomTabPanel = (props) => {
  const { children, value, tab, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== tab} {...other}>
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
};

const InventoryManagementPage = () => {
  const { pathname } = useLocation();
  let { path, url } = useRouteMatch();
  return (
    <div className="inventory-management">
      <div className="title">
        <h2>Inventory Manager</h2>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className="tabs">
          <Button
            className={`${pathname.includes('events') ? 'active' : ''}`}
            component={Link}
            to={`${url}/events`}
          >
            Events
          </Button>
          <Button
            className={`${pathname.includes('instruments') ? 'active' : ''}`}
            component={Link}
            to={`${url}/instruments`}
          >
            Instruments
          </Button>
          <Button
            className={`${pathname.includes('settings') ? 'active' : ''}`}
            component={Link}
            to={`${url}/settings/vendors`}
          >
            Settings
          </Button>
        </div>
      </Box>
      <Box sx={{ p: 3 }}>
        <Switch>
          <Route path={`${path}/events/at-a-glance`} component={EventAtGlance} />
          <Route path={`${path}/events/create`} component={EventDetails} />
          <Route path={`${path}/events/:id`} component={EventDetails} />
          <Route path={`${path}/events`} component={TabEvents} />

          <Route path={`${path}/instruments/create`} component={InstrumentDetails} />
          <Route path={`${path}/instruments/:id`} component={InstrumentDetails} />
          <Route path={`${path}/instruments`} component={TabInstruments} />

          <Route path={`${path}/settings`} component={TabSettings} />
        </Switch>
      </Box>
    </div>
  );
};

export default InventoryManagementPage;
