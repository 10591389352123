import React, { useEffect, useState } from 'react';
import CustomTable from '../../Table/CustomTable';
import ModalAddCategory from '../Modal/ModalAddCategory';
import {
  documentTypeColumns,
  eventStatusColumns,
  eventTypeColumns,
  instrumentStatusColumns
} from './Columns';
import { Button } from '@mui/material';
import {
  deleteInventorySettings,
  setInventorySettings,
  useInventorySettings,
  setInventoryEventNumber,
  useEventNumberSettings
} from '../../../util/db';
import { Col, Form, Row } from 'react-bootstrap';

export const SETTING_TYPE = {
  INSTRUMENT_STATUS: 'INSTRUMENT_STATUS',
  EVENT_STATUS: 'EVENT_STATUS',
  EVENT_TYPE: 'EVENT_TYPE',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE'
};

export const MODAL_TITLE = {
  INSTRUMENT_STATUS: 'Add Instrument Status',
  EVENT_STATUS: 'Add Event Status',
  EVENT_TYPE: 'Add Event Type',
  DOCUMENT_TYPE: 'Add Document Type'
};

const DefineCategories = () => {
  const { data: inventorySettingsInstrumentStatus = [] } = useInventorySettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: inventorySettingsEventStatus = [] } = useInventorySettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: inventorySettingsEventType = [] } = useInventorySettings(SETTING_TYPE.EVENT_TYPE);
  const { data: inventorySettingsDocumentType = [] } = useInventorySettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: eventNumberData = [] } = useEventNumberSettings();
  const [isOpenAddCategory, setOpenAddCategory] = useState(false);
  const [settingType, setSettingType] = useState('');
  const [title, setTitle] = useState('');
  const [eventNumber, setEventNumber] = useState(0);

  useEffect(() => {
    if (eventNumberData && eventNumberData.length > 0) {
      setEventNumber(eventNumberData[0].eventNumber);
    }
  }, [eventNumberData]);

  const handleOpenAddCategory = (type) => {
    setTitle(MODAL_TITLE[type]);
    setSettingType(type);
    setOpenAddCategory(true);
  };

  const handleUpdateEventNumber = () => {
    if (eventNumber) {
      setInventoryEventNumber(eventNumber);
    }
  };

  const handleSave = async (data) => {
    await setInventorySettings({ ...data, type: settingType });
    setOpenAddCategory(false);
  };

  const handleDelete = async (data) => {
    await deleteInventorySettings(data);
  };

  return (
    <>
      <div>
        <h2>User defined categories</h2>
      </div>
      <div className="event-number">
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Event Number</Form.Label>
            <Form.Control
              value={eventNumber}
              name="notificationLeadTime"
              type="number"
              onChange={(event) => {
                if (Number(event.target.value)) setEventNumber(Number(event.target.value));
                else {
                  setEventNumber('');
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>&nbsp;</Form.Label>
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  handleUpdateEventNumber();
                }}
              >
                Save
              </Button>
            </div>
          </Form.Group>
          <Form.Group as={Col}></Form.Group>
        </Row>
      </div>
      <div className="define-categories">
        <div className="table-box">
          <CustomTable
            data={inventorySettingsInstrumentStatus}
            header={instrumentStatusColumns}
            deleteRowData={handleDelete}
            numberOfRows={10}
            action={['delete']}
          />
          <div className="button-below">
            <Button
              variant="contained"
              onClick={() => handleOpenAddCategory(SETTING_TYPE.INSTRUMENT_STATUS)}
            >
              Add status
            </Button>
          </div>
        </div>

        <div className="table-box">
          <CustomTable
            data={inventorySettingsEventStatus}
            header={eventStatusColumns}
            deleteRowData={handleDelete}
            numberOfRows={10}
            action={['delete']}
          />
          <div className="button-below">
            <Button
              variant="contained"
              onClick={() => handleOpenAddCategory(SETTING_TYPE.EVENT_STATUS)}
            >
              Add status
            </Button>
          </div>
        </div>

        <div className="table-box">
          <CustomTable
            data={inventorySettingsEventType}
            header={eventTypeColumns}
            deleteRowData={handleDelete}
            numberOfRows={10}
            action={['delete']}
          />
          <div className="button-below">
            <Button
              variant="contained"
              onClick={() => handleOpenAddCategory(SETTING_TYPE.EVENT_TYPE)}
            >
              Add type
            </Button>
          </div>
        </div>

        <div className="table-box">
          <CustomTable
            data={inventorySettingsDocumentType}
            header={documentTypeColumns}
            deleteRowData={handleDelete}
            numberOfRows={10}
            action={['delete']}
          />
          <div className="button-below">
            <Button
              variant="contained"
              onClick={() => handleOpenAddCategory(SETTING_TYPE.DOCUMENT_TYPE)}
            >
              Add type
            </Button>
          </div>
        </div>
        <ModalAddCategory
          title={title}
          show={isOpenAddCategory}
          handleClose={() => setOpenAddCategory(false)}
          handleSave={handleSave}
        />
      </div>
    </>
  );
};

export default DefineCategories;
