import { Button } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { listVendorColumns, VendorStatusOptions } from './Columns';
import { setInventoryVendors, useInventoryVendors } from '../../../util/db';
import ModalFormVendor from '../Modal/ModalFormVendor';
import { alphaNumericSorterArrayString } from '../../../util/util';

const VendorList = () => {
  const [filterModel, setFilterModel] = useState({});
  const { data: vendors = [] } = useInventoryVendors(filterModel);
  const [isOpen, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [searchValue, setSearchValue] = useState();
  useEffect(() => {
    if (companies.length == 0) {
      setCompanies(
        Array.from(new Set(vendors.map((item) => item.companyName))).sort(
          alphaNumericSorterArrayString
        )
      );
    }
  }, [vendors]);

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const handleSave = async (data) => {
    await setInventoryVendors(data);
    setOpen(false);
  };

  return (
    <>
      <div className="filter">
        <div>
          <Row>
            <Form.Group as={Col} controlId="company">
              <Form.Label>Company</Form.Label>
              <Form.Select onChange={(event) => handleChange('companyName', event)}>
                <option value="">All companies</option>
                {companies.map((item) => (
                  <option key={item}>{item}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Select onChange={(event) => handleChange('status', event)}>
                <option value="">All status</option>
                {VendorStatusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="search">
              <Form.Label>Search</Form.Label>
              <Form.Control
                placeholder="All fields"
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </Form.Group>
          </Row>
        </div>
      </div>
      <div className="grid-view">
        <div className="inner-actions">
          <h2>Vendors</h2>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add new
          </Button>
          <ModalFormVendor
            show={isOpen}
            handleClose={() => setOpen(false)}
            handleSave={handleSave}
          />
        </div>
        <CustomTable
          numberOfRows={20}
          data={vendors.filter((row) => {
            if (searchValue) {
              return (
                row.id.includes(searchValue.toLowerCase()) ||
                row.companyName.toLowerCase().includes(searchValue.toLowerCase()) ||
                row.department.toLowerCase().includes(searchValue.toLowerCase())
              );
            } else {
              return true;
            }
          })}
          header={listVendorColumns}
          sort={{ sorting: { sortModel: [{ field: 'vendorId', sort: 'asc' }] } }}
          sx={{
            '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
              display: 'none'
            }
          }}
        />
      </div>
    </>
  );
};

export default VendorList;
