import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import Form from 'react-bootstrap/Form';
import ChartContext from './DataEntrySection/DataEntryProvider';
import { wFlagMrCharttColumns, oocFlagMrCharttColumns } from '../components/Columns';
import { createCustomizedDot } from '../util/util';

function SQCMovingRangeChart({ data, lines, syncId, xAxis, yMin, yMax }) {
  const formatXAxis = (tickItem) => {
    return String(data[tickItem]?.date) || '';
  };

  const [setupDataChecked, setSetupDataChecked] = useState(false);
  const [warningFlagsChecked, setWarningFlagsChecked] = useState(true);
  const [outOfControlChecked, setOutOfControlChecked] = useState(true);
  const [runChartsChecked, setRunChartsChecked] = useState(true);
  const { currentControlStrategyConfigurations } = useContext(ChartContext);

  const formatNothing = () => {
    return '';
  };

  const formatYAxis = (tickItem) => {
    return tickItem.toPrecision(currentControlStrategyConfigurations.chartSigDigits);
  };

  const handleSetupDataCheck = (e) => {
    setSetupDataChecked(e.target.checked);
  };

  const handleWarningFlagCheck = (e) => {
    setWarningFlagsChecked(e.target.checked);
  };

  const handleRunChartsCheck = (e) => {
    setRunChartsChecked(e.target.checked);
  };

  const handleOutOfControlCheck = (e) => {
    setOutOfControlChecked(e.target.checked);
  };

  const renderReferenceLines = (rcc) => {
    if (rcc) {
      return (
        <>
          {lines
            .filter((l) => l.reference)
            .map((line) => (
              <ReferenceLine
                y={line.overrideValue || data[0][line.yAxis]}
                isAnimationActive={false}
                key={line.yAxis || line.name}
                stroke={line.color}
                label={line.label}
              />
            ))}
        </>
      );
    }
    return (
      <>
        {lines
          .filter((l) => l.reference)
          .map((line) => (
            <ReferenceLine
              y={line.overrideValue || data[0][line.yAxis]}
              isAnimationActive={false}
              key={line.yAxis || line.name}
              stroke="transparent"
              label=""
            />
          ))}
      </>
    );
  };

  const renderCValue = (c) => {
    let valueToReturn = 0;
    if (c !== undefined) {
      valueToReturn = c - 3;
    }
    return valueToReturn;
  };

  const checkWarning = (payload) => {
    return wFlagMrCharttColumns.find((f) => !!payload[f]) !== undefined;
  };

  const checkOOC = (payload) => {
    return oocFlagMrCharttColumns.find((f) => !!payload[f]) !== undefined;
  };

  const CustomizedDot = ({ cx, cy, payload, dataKey }) => {
    let dotToReturn = [];
    if (dataKey === 'mrOmits') {
      const isWarning = checkWarning(payload);
      const isOOC = checkOOC(payload);
      dotToReturn = createCustomizedDot(
        cx,
        cy,
        payload,
        setupDataChecked,
        isWarning,
        isOOC,
        warningFlagsChecked,
        outOfControlChecked
      );
    }
    return dotToReturn;
  };

  return (
    <>
      <div className="checkbox-buttons-container">
        <div className="checkbox-buttons">
          Setup data = 1 <Form.Check value={setupDataChecked} onChange={handleSetupDataCheck} />
        </div>
        <div className="checkbox-buttons">
          Warning flags{' '}
          <Form.Check checked={warningFlagsChecked} onChange={handleWarningFlagCheck} />
        </div>
        <div className="checkbox-buttons">
          Out of Control{' '}
          <Form.Check checked={outOfControlChecked} onChange={handleOutOfControlCheck} />
        </div>
        <div className="checkbox-buttons">
          Reference Lines <Form.Check checked={runChartsChecked} onChange={handleRunChartsCheck} />
        </div>
      </div>
      <ResponsiveContainer height={400}>
        <LineChart
          data={data}
          syncId={syncId}
          margin={{
            top: 5,
            right: 20,
            bottom: 15,
            left: 0
          }}
        >
          {lines
            .filter((l) => !l.reference)
            .map((line) => (
              <Line
                type="linear"
                key={line.yAxis || line.name}
                isAnimationActive={false}
                dataKey={line.yAxis}
                stroke={line.color}
                name={line.name}
                dot={<CustomizedDot key={uuidv4()} />}
              />
            ))}
          {renderReferenceLines(runChartsChecked)}

          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey={xAxis} tickFormatter={formatXAxis} />
          <YAxis type="number" tickFormatter={formatYAxis} domain={[yMin, yMax]} />
          <Brush dataKey={xAxis} ticketFormatter={formatNothing} />
          <Legend verticalAlign="top" height={36} />
          <Tooltip formatter={(value) => `! ${value}`} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

SQCMovingRangeChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAxis: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired
};

export default SQCMovingRangeChart;
