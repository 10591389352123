import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import Form from 'react-bootstrap/Form';
import ChartContext from './DataEntrySection/DataEntryProvider';
import { wFlagIChartColumns, oocFlagIChartColumns } from '../components/Columns';
import { createCustomizedDot } from '../util/util';

function SQCLineChart({ data, lines, syncId, xAxis, yMin, yMax, isEverFlags }) {
  const formatXAxis = (tickItem) => {
    return String(data[tickItem]?.date) || '';
  };
  const { currentControlStrategyConfigurations } = useContext(ChartContext);
  const [setupDataChecked, setSetupDataChecked] = useState(false);
  const [warningFlagsChecked, setWarningFlagsChecked] = useState(true);
  const [outOfControlChecked, setOutOfControlChecked] = useState(true);
  const [runChartsChecked, setRunChartsChecked] = useState(true);
  const [everFlagsChecked, setEverFlagsChecked] = useState(false);

  const formatYAxis = (tickItem) => {
    return tickItem.toPrecision(currentControlStrategyConfigurations.chartSigDigits);
  };

  const handleSetupDataCheck = (e) => {
    setSetupDataChecked(e.target.checked);
  };

  const handleWarningFlagCheck = (e) => {
    setWarningFlagsChecked(e.target.checked);
  };

  const handleOutOfControlCheck = (e) => {
    setOutOfControlChecked(e.target.checked);
  };

  const handleRunChartsCheck = (e) => {
    setRunChartsChecked(e.target.checked);
  };

  const handleEverFlagsCheck = (e) => {
    setEverFlagsChecked(e.target.checked);
  };

  const renderToleranceLines = (line) => {
    if (
      line.name === 'User Defined LTL' &&
      currentControlStrategyConfigurations.lowerToleranceLimit !== 'N/A'
    ) {
      return (
        <Line
          type="linear"
          key={uuidv4()}
          isAnimationActive={false}
          dataKey={line.yAxis}
          stroke={line.color}
          label="Test"
          name={line.name}
          dot={<CustomizedDot key={uuidv4()} />}
          strokeDasharray="12"
        />
      );
    }
    if (
      line.name === 'User Defined UTL' &&
      currentControlStrategyConfigurations.upperToleranceLimit !== 'N/A'
    ) {
      return (
        <Line
          type="linear"
          key={uuidv4()}
          isAnimationActive={false}
          dataKey={line.yAxis}
          stroke={line.color}
          label="Test"
          name={line.name}
          dot={<CustomizedDot key={uuidv4()} />}
          strokeDasharray="12"
        />
      );
    }
    return <></>;
  };

  const renderControlLines = (line) => {
    if (
      line.name === 'User Defined LCL' &&
      currentControlStrategyConfigurations.lowerControlLimit !== 'N/A'
    ) {
      return (
        <Line
          type="linear"
          key={uuidv4()}
          isAnimationActive={false}
          dataKey={line.yAxis}
          stroke={line.color}
          label="Test"
          name={line.name}
          dot={<CustomizedDot key={uuidv4()} />}
          strokeDasharray="12"
        />
      );
    }
    if (
      line.name === 'User Defined UCL' &&
      currentControlStrategyConfigurations.upperControlLimit !== 'N/A'
    ) {
      return (
        <Line
          type="linear"
          key={uuidv4()}
          isAnimationActive={false}
          dataKey={line.yAxis}
          stroke={line.color}
          label="Test"
          name={line.name}
          dot={<CustomizedDot key={uuidv4()} />}
          strokeDasharray="12"
        />
      );
    }
    return <></>;
  };

  const renderReferenceLines = (rcc) => {
    if (rcc) {
      return (
        <>
          {lines
            .filter(
              (l) =>
                l.reference &&
                ![
                  'User Defined LTL',
                  'User Defined UTL',
                  'User Defined LCL',
                  'User Defined UCL'
                ].includes(l.name)
            )
            .map((line) => (
              <Line
                type="linear"
                key={uuidv4()}
                isAnimationActive={false}
                dataKey={line.yAxis}
                stroke={line.color}
                label="Test"
                name={line.name}
                dot={<CustomizedDot key={uuidv4()} />}
              />
            ))}
          {lines
            .filter(
              (l) =>
                l.reference &&
                ![
                  'User Defined LTL',
                  'User Defined UTL',
                  'User Defined LCL',
                  'User Defined UCL'
                ].includes(l.name)
            )
            .map((line) => (
              <ReferenceLine
                y={line.overrideValue || data[data.length - 1][line.yAxis]}
                key={uuidv4()}
                stroke="transparent"
                label={line.label}
              />
            ))}
          {lines
            .filter(
              (l) => l.reference && (l.name === 'User Defined LTL' || l.name === 'User Defined UTL')
            )
            .map((line) => renderToleranceLines(line))}
          {lines
            .filter(
              (l) => l.reference && l.name !== 'User Defined LCL' && l.name !== 'User Defined UCL'
            )
            .map((line) => (
              <ReferenceLine
                y={line.overrideValue || data[data.length - 1][line.yAxis]}
                key={uuidv4()}
                stroke="transparent"
                label={line.label}
              />
            ))}
          {lines
            .filter(
              (l) => l.reference && (l.name === 'User Defined LCL' || l.name === 'User Defined UCL')
            )
            .map((line) => renderControlLines(line))}
        </>
      );
    }
    return (
      <>
        {lines
          .filter((l) => l.reference)
          .map((line) => (
            <Line
              type="linear"
              key={uuidv4()}
              isAnimationActive={false}
              dataKey={line.yAxis}
              stroke="transparent"
              label=""
              name={line.name}
              dot={<CustomizedDot key={uuidv4()} />}
            />
          ))}
        {lines
          .filter((l) => l.reference)
          .map((line) => (
            <ReferenceLine
              y={line.overrideValue || data[data.length - 1][line.yAxis]}
              key={uuidv4()}
              stroke="transparent"
              label=""
            />
          ))}
      </>
    );
  };

  const checkWarning = (payload) => {
    return wFlagIChartColumns.find((f) => !!payload[f]) !== undefined;
  };

  const checkOOC = (payload) => {
    return oocFlagIChartColumns.find((f) => !!payload[f]) !== undefined;
  };

  const CustomizedDot = ({ cx, cy, payload, dataKey }) => {
    let dotToReturn = [];
    if (dataKey === 'resultOmits') {
      const isWarning = checkWarning(payload);
      const isOOC = checkOOC(payload);
      dotToReturn = createCustomizedDot(
        cx,
        cy,
        payload,
        setupDataChecked,
        isWarning,
        isOOC,
        warningFlagsChecked,
        outOfControlChecked
      );
    }

    return dotToReturn;
  };

  const renderDataLines = (rcc) => {
    if (rcc) {
      return (
        <>
          {lines
            .filter((l) => !l.reference)
            .map((line) => (
              <Line
                type="linear"
                key={line.yAxis || line.name}
                isAnimationActive={false}
                dataKey={line.yAxis}
                stroke={line.color}
                name={line.name}
                dot={<CustomizedDot key={uuidv4()} />}
              />
            ))}
        </>
      );
    }
    return (
      <>
        <Line
          type="linear"
          key={'resultOmits' || 'Data'}
          isAnimationActive={false}
          dataKey="resultOmits"
          stroke="#32CD32"
          name="Data"
          dot={<CustomizedDot key={uuidv4()} />}
        />
      </>
    );
  };

  const EverFlagsTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p>{label}</p>
          <p
            style={{
              color: 'green'
            }}
          >
            Data: {data.result}
          </p>
          {data.everFlags ? (
            <>
              {data.everFlags.map((item) => {
                let color = 'yellow';
                if (item.flags.includes('W:')) {
                  color = 'gold';
                }
                if (item.flags.includes('OOC:')) {
                  color = 'red';
                }
                return (
                  <p
                    key={item.version}
                    style={{
                      color: color
                    }}
                  >
                    {`V${item.version}: ${item.flags}`}
                  </p>
                );
              })}
            </>
          ) : (
            <p>None</p>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <div className="checkbox-buttons-container">
        <div className="checkbox-buttons">
          Setup data = 1 <Form.Check value={setupDataChecked} onChange={handleSetupDataCheck} />
        </div>
        <div className="checkbox-buttons">
          Warning flags{' '}
          <Form.Check checked={warningFlagsChecked} onChange={handleWarningFlagCheck} />
        </div>
        <div className="checkbox-buttons">
          Out of Control{' '}
          <Form.Check checked={outOfControlChecked} onChange={handleOutOfControlCheck} />
        </div>
        <div className="checkbox-buttons">
          Reference Lines <Form.Check checked={runChartsChecked} onChange={handleRunChartsCheck} />
        </div>
        {isEverFlags && (
          <div className="checkbox-buttons">
            EverFlags <Form.Check checked={everFlagsChecked} onChange={handleEverFlagsCheck} />
          </div>
        )}
      </div>
      <ResponsiveContainer height={400}>
        <LineChart
          data={data}
          syncId={syncId}
          margin={{
            top: 5,
            right: 20,
            bottom: 15,
            left: 0
          }}
        >
          {renderDataLines(runChartsChecked)}
          {renderReferenceLines(runChartsChecked)}
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey={xAxis} tickFormatter={formatXAxis} />
          <YAxis type="number" tickFormatter={formatYAxis} domain={[yMin, yMax]} />
          <Brush dataKey={xAxis} />
          <Legend verticalAlign="top" height={56} />
          {everFlagsChecked ? (
            <Tooltip content={<EverFlagsTooltip />} />
          ) : (
            <Tooltip formatter={(value) => `${value}`} />
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

SQCLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAxis: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired
};

export default SQCLineChart;
