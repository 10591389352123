import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalError = ({ show, setShow, title, message, textButton }) => {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>{title}</Modal.Header>

        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {textButton}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalError;
