import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import './InstrumentSection.scss';
import Modal from 'react-bootstrap/Modal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../util/firebase';
import {
  useGetDepartments,
  useGetIndexValues,
  useGetInstruments,
  useGetPersonnel,
  useGetSetupGroups
} from '../../util/db';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { useAuth } from '../../util/auth';
import CustomTable from '../Table/CustomTable';
import { RULES, SCREEN } from '../../util/Constant';
import {
  checkDelete,
  checkUpdate,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';

const functions = getFunctions(firebaseApp);
const updateSetupGroupsFunction = httpsCallable(functions, 'updateSetupGroups');

function InstrumentSection() {
  const auth = useAuth();
  const [currentInstruments, setCurrentInstruments] = useState([]);
  const { data: instruments } = useGetInstruments();
  const [instrumentsToUpdate, setInstrumentsToUpdate] = useState([]);
  const { data: personnel } = useGetPersonnel();
  const [currentDepartments, setCurrentDepartments] = useState([]);
  const { data: departments } = useGetDepartments();
  const [showModal, setShowModal] = useState(false);
  const { data: indexes } = useGetIndexValues();
  const [currentIndexes, setCurrentIndexes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const [permission, setPermission] = useState(null);
  const { data: setupGroups } = useGetSetupGroups();
  const defaultInstrument = {
    location: '',
    department: '',
    name: '',
    serialNumber: '',
    manufacturer: '',
    model: '',
    notes: '',
    recType: 'instruments'
  };
  const [currentModalInstrument, setCurrentModalInstrument] = useState(defaultInstrument);
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);

  useEffect(() => {
    if (permission) {
      const value = [];
      if (checkDelete(permission, SCREEN.INSTRUMENT)) {
        value.push('delete');
      }
      if (checkUpdate(permission, SCREEN.INSTRUMENT)) {
        setHasEdit(checkUpdate(permission, SCREEN.INSTRUMENT));
        value.push('edit');
      }
      setActions(value);
    }
  }, [permission]);

  useEffect(() => {
    if (Array.isArray(setupGroups) && Array.isArray(instruments) && auth) {
      const setupGroupsAssigned = [];
      const instrumentsToReturn = [];
      setupGroups.forEach((setupGroup) => {
        const matchingUser = setupGroup.personnel.find(
          (person) => person.email === auth.user.email
        );
        if (matchingUser) {
          setupGroupsAssigned.push(setupGroup);
        }
      });
      setupGroupsAssigned.forEach((assignedSetupGroup) => {
        assignedSetupGroup.instruments.forEach((instrumentAssigned) => {
          const existingInstrument = instruments.find(
            (instrument) => instrument.id === instrumentAssigned
          );
          if (existingInstrument) {
            instrumentsToReturn.push(existingInstrument);
          }
        });
      });
      setCurrentInstruments(instrumentsToReturn);
    }
  }, [setupGroups, instruments, auth]);

  const saveInstruments = () => {
    const currentInstrumentsToUpdate = instrumentsToUpdate;
    currentInstrumentsToUpdate.forEach((instrument) => {
      updateSetupGroupsFunction({ updateType: 'update', instrumentObject: instrument });
    });
    setInstrumentsToUpdate([]);
  };

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
    }
  }, [personnel]);

  const renderLocationOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.name}>
          {option.name}
        </option>
      );
    });
    return rows;
  };

  useEffect(() => {
    if (Array.isArray(departments) && departments.length > 0) {
      const departmentsDropdown = departments.map((department) => {
        const departmentToReturn = {
          id: department.id,
          value: department.name,
          label: department.name
        };

        return departmentToReturn;
      });
      setCurrentDepartments(departmentsDropdown);
    }
  }, [departments]);

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      setCurrentIndexes(indexes[0].index);
    }
  }, [indexes]);

  useEffect(() => {
    const updatedLocationsPersonnel = [];
    currentIndexes.forEach((index) => {
      const locationToPush = {
        id: index.name,
        name: index.name,
        value: index.name,
        label: index.name
      };
      updatedLocationsPersonnel.push(locationToPush);
    });
    setAllLocations(updatedLocationsPersonnel);
  }, [currentIndexes]);

  const dataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: hasEdit,
      flex: 4,
      sortable: false,
      type: 'singleSelect',
      valueOptions: allLocations
    },
    {
      field: 'department',
      headerName: 'Department',
      editable: hasEdit,
      flex: 2,
      sortable: false,
      type: 'singleSelect',
      valueOptions: currentDepartments
    },
    {
      field: 'name',
      headerName: 'Instrument Name',
      editable: hasEdit,
      flex: 3,
      sortable: true
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      editable: hasEdit,
      flex: 2,
      sortable: false
    },
    {
      field: 'manufacturer',
      headerName: 'Manufacturer',
      editable: hasEdit,
      flex: 2,
      sortable: false
    },
    {
      field: 'model',
      headerName: 'Model',
      editable: hasEdit,
      flex: 2,
      sortable: false
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: hasEdit,
      flex: 2,
      sortable: false
    }
  ];

  const handleResults = (results) => {
    updateSetupGroupsFunction({
      updateType: 'addList',
      instrumentObject: results
        .map((instrument) => {
          if (instrument[0] && instrument[0] !== 'Location') {
            return {
              location: instrument[0],
              department: instrument[1],
              name: instrument[2],
              serialNumber: instrument[3],
              manufacturer: instrument[4],
              model: instrument[5],
              notes: instrument[6],
              recType: 'instruments'
            };
          } else {
            return null;
          }
        })
        .filter(Boolean)
    });
  };

  const handleInstrumentAdd = () => {
    updateSetupGroupsFunction({ updateType: 'add', instrumentObject: currentModalInstrument });
    setShowModal(false);
    setCurrentModalInstrument(defaultInstrument);
  };

  const renderOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.value}>
          {option.label}
        </option>
      );
    });
    return rows;
  };

  const handleInstrumentChange = (e, propertyName) => {
    setCurrentModalInstrument({
      ...currentModalInstrument,
      [propertyName]: e.target.value
    });
  };

  return (
    <div>
      <div className="header">
        <h2>Instruments</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <CustomTable
          numberOfRows={10}
          data={currentInstruments}
          header={dataEntryTableColumns}
          action={actions}
          updateRowData={(data) => {
            setInstrumentsToUpdate([...instrumentsToUpdate, data]);
          }}
          deleteRowData={(data) => {
            updateSetupGroupsFunction({ updateType: 'delete', instrumentObject: data });
          }}
          sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
        />
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Instrument',
              () => setShowModal(true),
              SCREEN.INSTRUMENT,
              RULES.CREATE,
              permission
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permission,
            SCREEN.INSTRUMENT,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save data',
            () => saveInstruments(),
            SCREEN.INSTRUMENT,
            RULES.CREATE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Instrument</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <Form.Select
                value={currentModalInstrument.location}
                onChange={(e) => handleInstrumentChange(e, 'location')}
                aria-label="Default select example"
              >
                <option value="">Assign a location</option>
                {renderLocationOptions(allLocations)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Select
                value={currentModalInstrument.department}
                onChange={(e) => handleInstrumentChange(e, 'department')}
                aria-label="Default select example"
              >
                <option value="">Select department</option>
                {renderOptions(currentDepartments)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={currentModalInstrument.name}
                onChange={(e) => handleInstrumentChange(e, 'name')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Serial Number</Form.Label>
              <Form.Control
                value={currentModalInstrument.serialNumber}
                onChange={(e) => handleInstrumentChange(e, 'serialNumber')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Manufacturer</Form.Label>
              <Form.Control
                value={currentModalInstrument.manufacturer}
                onChange={(e) => handleInstrumentChange(e, 'manufacturer')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Model</Form.Label>
              <Form.Control
                value={currentModalInstrument.model}
                onChange={(e) => handleInstrumentChange(e, 'model')}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                value={currentModalInstrument.notes}
                onChange={(e) => handleInstrumentChange(e, 'notes')}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleInstrumentAdd()} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InstrumentSection;
