import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { getFunctions, httpsCallable } from 'firebase/functions';

import Alert from 'react-bootstrap/Alert';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { uniq, uniqBy } from 'lodash';
import './DataEntrySection.scss';
import { useAuth } from '../../util/auth';
import { textSort } from '../../util/util';
import {
  createSilencedData,
  useGetIndexValues,
  useGetInstruments,
  useGetMethods,
  useGetSamples,
  useGetSilencedDataByShortKey,
  useGetScheduledSamples,
  deleteScheduledSample,
  createScheduledSamples
} from '../../util/db';
import firebaseApp, { getCurrentFeatureFlags } from '../../util/firebase';
import ChartContext from './DataEntryProvider';
import CustomTable from '../Table/CustomTable';
import { dayTimeComparator } from '../Columns';
import { RULES, SCREEN } from '../../util/Constant';
import {
  checkReadOnly,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';

const functions = getFunctions(firebaseApp);
const addDataFunction = httpsCallable(functions, 'addData');
const runDataFunction = httpsCallable(functions, 'runData');

function DataEntrySection() {
  const auth = useAuth();
  const currentTime = moment(Date.now()).format('MM/DD/YYYY hh:mm A');
  const [currentInstrumentsDropdown, setCurrentInstrumentsDropdown] = useState([]);
  const [currentLocations, setCurrentLocations] = useState([]);
  const { data: indexes } = useGetIndexValues();
  const { data: methods } = useGetMethods();
  const { data: instruments } = useGetInstruments();
  const [currentMethodsDropdown, setCurrentMethodsDropdown] = useState([]);
  const [currentSamplesDropdown, setCurrentSamplesDropdown] = useState([]);
  const { data: samples } = useGetSamples();
  const [currentPersonnelDropdown, setCurrentPersonnelDropdown] = useState([]);
  const [radioValue, setRadioValue] = useState('1');
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showDataAlert, setShowDataAlert] = useState(false);
  const [recheckAlert, setRecheckAlert] = useState(false);
  const [addDataAlert, setAddDataAlert] = useState(false);
  const [currentSilencedData, setCurrentSilencedData] = useState([]);
  const [dataToRecheck, setDataToRecheck] = useState([]);
  const {
    currentChartGroupConfigurations,
    currentControlStrategyConfigurations,
    currentSummary,
    currentShortKey,
    chartData,
    permissionData,
    personnel,
    currentPersonnel,
    summaries
  } = useContext(ChartContext);
  const { data: scheduledSamples } = useGetScheduledSamples();
  const { data: silencedData } = useGetSilencedDataByShortKey(currentShortKey);
  const [currentScheduledSamples, setCurrentScheduledSamples] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [featureFlags, setFeatureFlags] = useState(getCurrentFeatureFlags);
  const [radios] = useState([
    { name: 'Scheduled & Recheck Data', value: '1' },
    { name: 'Silenced Data', value: '2' }
  ]);
  const [deleteList, setDeleteList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const silenceOptions = [
    {
      value: 'No',
      label: 'No'
    },
    {
      value: 'Yes',
      label: 'Yes'
    }
  ];
  const pageSizeDefault = 20;

  useEffect(() => {
    if (
      Array.isArray(scheduledSamples) &&
      scheduledSamples.length > 0 &&
      currentPersonnel.length > 0
    ) {
      const matchingUser = currentPersonnel.find((person) => person.email === auth.user.email);
      const additionalProperties = {
        personnel: matchingUser ? matchingUser.name : auth.user.email,
        setupData: 0,
        result: ''
      };

      const mappedSamples = scheduledSamples.map((sample) => {
        let ucl_lcl = {};
        if (true) {
          const findSummary = summaries.find((item) => {
            return item.id === sample.fullKey;
          });
          if (findSummary) {
            ucl_lcl = {
              lowerControlLimit: Math.round(findSummary.lcl_sd * 100) / 100,
              upperControlLimit: Math.round(findSummary.ucl_sd * 100) / 100
            };
          }
        }
        return {
          ...sample,
          ...additionalProperties,
          ...ucl_lcl
        };
      });
      setCurrentScheduledSamples(mappedSamples);
    }
  }, [scheduledSamples, currentPersonnel]);

  const handleRadioChange = (e) => {
    if (e.currentTarget.value === '2') {
      setRecheckAlert(false);
    } else if (dataToRecheck.length > 0) {
      setRecheckAlert(true);
    }

    setRadioValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (Array.isArray(silencedData) && silencedData.length > 0) {
      setCurrentSilencedData(silencedData);
    }
  }, [silencedData]);

  useEffect(() => {
    if (Array.isArray(samples) && samples.length > 0) {
      samples.sort((a, b) => a.name.localeCompare(b.name));
      const samplesDropdown = samples.map((sample) => {
        const sampleToReturn = {
          id: sample.id,
          value: sample.name,
          label: sample.name
        };

        return sampleToReturn;
      });
      setCurrentSamplesDropdown(samplesDropdown);
    }
  }, [samples]);

  useEffect(() => {
    if (Array.isArray(indexes) && indexes.length > 0) {
      const locations = [];
      indexes[0].index.forEach((location) => {
        const locationToReturn = {
          value: location.name,
          label: location.name
        };
        locations.push(locationToReturn);
      });
      setCurrentLocations(locations);
    }
  }, [indexes]);

  useEffect(() => {
    const updatedDataToRecheck = [];
    const oocDataToRecheck = structuredClone(chartData).filter((dataPoint) =>
      dataPoint.flags.includes('OOC')
    );
    oocDataToRecheck.forEach((dataPoint) => {
      if (typeof dataPoint.sampleId === 'string' && !dataPoint.sampleId.includes('_recheck')) {
        const newDataPoint = { ...dataPoint };
        newDataPoint.sampleId += '_recheck';
        // add data point with same data but updated sample ID
        newDataPoint.flags = '';
        newDataPoint.silenceReason = '';
        newDataPoint.result = '';
        updatedDataToRecheck.push(newDataPoint);
      }
    });
    setDataToRecheck(updatedDataToRecheck);
  }, [chartData]);

  useEffect(() => {
    if (currentChartGroupConfigurations.autoCreateSampleRecheck === 'Yes') {
      setRecheckAlert(true);
    } else {
      setRecheckAlert(false);
    }
  }, [currentChartGroupConfigurations.autoCreateSampleRecheck]);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      personnel.sort((a, b) => {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return nameA.localeCompare(nameB);
      });
      const personnelDropdown = personnel.map((person) => {
        const personToReturn = {
          id: person.id,
          value: person.name,
          label: person.name
        };

        return personToReturn;
      });
      setCurrentPersonnelDropdown(personnelDropdown);
    }
  }, [personnel]);

  useEffect(() => {
    if (Array.isArray(instruments) && instruments.length > 0) {
      instruments.sort((a, b) => {
        const nameA = a.name || '';
        const nameB = b.name || '';
        return nameA.localeCompare(nameB);
      });
      const uniqueInstruments = uniqBy(instruments, (instrument) => instrument.name);
      const instrumentsDropdown = uniqueInstruments.map((instrument) => {
        const instrumentToReturn = {
          id: instrument.id,
          value: instrument.name,
          label: instrument.name
        };

        return instrumentToReturn;
      });
      setCurrentInstrumentsDropdown(instrumentsDropdown);
    }
  }, [instruments]);

  const renderAlert = (renderRecheckAlert) => {
    if (renderRecheckAlert) {
      return (
        <Alert key="warning" onClick={() => setShow(false)} dismissible variant="warning">
          You have {dataToRecheck.length} samples that have been flagged for recheck.
        </Alert>
      );
    }
    return <></>;
  };

  const renderDataSuccessAlert = (dataAlert) => {
    if (dataAlert) {
      return (
        <Alert key="success" onClick={() => setAddDataAlert(false)} dismissible variant="success">
          Successfully added data
        </Alert>
      );
    }
    return <></>;
  };

  const renderDropdownParameter = (data, key) => {
    const methodSelect = data.row.method;
    const parameterFilter = methods
      .filter((item) => item.method === methodSelect)
      .map((value) => {
        return {
          value: value[key],
          label: value[key]
        };
      });

    const parameterFilterUniq = uniqBy(parameterFilter, (item) => item.label);
    parameterFilterUniq.sort(textSort);
    return parameterFilterUniq;
  };

  const deleteRow = (rowIn) => {
    const row = rowIn;
    setDeleteList([...deleteList, rowIn]);
    setCurrentScheduledSamples(currentScheduledSamples.filter((item) => item.id !== row.id));
  };

  const dataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentLocations,
      flex: 4
    },
    {
      field: 'method',
      headerName: 'Method',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentMethodsDropdown,
      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      editable: true,
      type: 'singleSelect',
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      flex: 2
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentInstrumentsDropdown,
      flex: 2
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentSamplesDropdown,
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      editable: true,
      flex: 3,
      valueGetter: (params) => {
        return moment(params.value).format('MM/DD/YYYY hh:mm A');
      }
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      editable: true,
      type: 'singleSelect',
      flex: 3,
      valueOptions: currentPersonnelDropdown
    },
    {
      field: 'sampleId',
      editable: true,
      headerName: 'Sample ID',
      type: 'string',
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const huntTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      editable: true,
      type: 'singleSelect',
      valueOptions: currentLocations,
      flex: 4
    },
    {
      field: 'method',
      headerName: 'Method',
      valueOptions: currentMethodsDropdown,
      editable: true,
      type: 'singleSelect',

      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      editable: true,
      type: 'singleSelect',
      flex: 2
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      valueOptions: currentInstrumentsDropdown,
      editable: true,
      type: 'singleSelect',
      flex: 2
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      valueOptions: currentSamplesDropdown,
      editable: true,
      type: 'singleSelect',
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      editable: true,
      flex: 3
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      valueOptions: currentPersonnelDropdown,
      editable: true,
      type: 'singleSelect',
      flex: 3
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      type: 'string',
      editable: true,
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'lowerControlLimit',
      headerName: 'LCL',
      editable: true,
      flex: 2
    },
    {
      field: 'upperControlLimit',
      headerName: 'UCL',
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const cellEditProp = {
    mode: 'click',
    blurToSave: true,
    afterSaveCell: (oldValue, newValue, rowIn, column) => {
      const row = rowIn;
      row[column.dataField] = newValue;
    }
  };

  const renderScheduledDataTable = (instanceFeatureFlags) => {
    const tableToReturn = [];
    if (instanceFeatureFlags.includes('lcl-data-entry-columns')) {
      tableToReturn.push(
        <>
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentScheduledSamples}
            setData={setCurrentScheduledSamples}
            action={['delete', 'edit']}
            header={huntTableColumns}
            deleteRowData={deleteRow}
          />
        </>
      );
    } else {
      tableToReturn.push(
        <>
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentScheduledSamples}
            setData={setCurrentScheduledSamples}
            header={dataEntryTableColumns}
            action={['delete', 'edit']}
            deleteRowData={deleteRow}
          />
        </>
      );
    }
    return tableToReturn;
  };

  const dataEntryTableColumnsReadOnly = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 2,
      minWidth: 300
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      flex: 1,
      minWidth: 150
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      flex: 1,
      minWidth: 150
    },
    {
      field: 'result',
      headerName: 'Result',
      flex: 1,
      minWidth: 150
    }
  ];

  const addRow = () => {
    const matchingUser = currentPersonnel.find((person) => person.email === auth.user.email);
    const rowToAdd = {
      id: uuidv4(),
      location: '',
      method: '',
      parameter: '',
      instrument: '',
      sampleName: '',
      dateTime: currentTime,
      personnel: matchingUser ? matchingUser.name : auth.user.email,
      sampleId: '',
      result: '',
      setupData: 0,
      delete: '',
      isNew: true
    };
    setCurrentScheduledSamples([...currentScheduledSamples, rowToAdd]);
  };

  const recheckDataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 4,
      valueOptions: currentLocations,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 2,
      valueOptions: currentMethodsDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 2,
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      flex: 2,
      valueOptions: currentInstrumentsDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      flex: 2,
      valueOptions: currentSamplesDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'dateTime',
      editable: true,
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      flex: 3
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 3,
      valueOptions: currentPersonnelDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      editable: true,
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'silence',
      headerName: 'Silence?',
      flex: 2,
      valueOptions: silenceOptions,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'silenceReason',
      headerName: 'Reason for Silencing',
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const recheckDataEntryTableColumnsReadOnly = [
    {
      field: 'location',
      flex: 4,
      headerName: 'Location'
    },
    {
      field: 'method',
      flex: 2,
      headerName: 'Method'
    },
    {
      field: 'parameter',
      flex: 2,
      headerName: 'Parameter'
    },
    {
      field: 'instrument',
      flex: 2,
      headerName: 'Instrument'
    },
    {
      field: 'sampleName',
      flex: 2,
      headerName: 'Sample Name'
    },
    {
      field: 'dateTime',
      flex: 3,
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator
    },
    {
      field: 'personnel',
      flex: 2,
      headerName: 'Personnel'
    },
    {
      field: 'sampleId',
      flex: 3,
      headerName: 'Sample ID'
    },
    {
      field: 'setupData',
      flex: 2,
      headerName: 'Setup Data'
    },
    {
      field: 'silence',
      flex: 2,
      headerName: 'Silence?'
    },
    {
      field: 'silenceReason',
      flex: 2,
      headerName: 'Reason for Silencing'
    },
    {
      field: 'result',
      flex: 2,
      headerName: 'Result'
    }
  ];

  const silencedDataEntryTableColumns = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 2,
      valueOptions: currentLocations,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 2,
      valueOptions: currentMethodsDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 2,
      valueOptions: (data) => {
        return renderDropdownParameter(data, data.field);
      },
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      flex: 2,
      valueOptions: currentInstrumentsDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      flex: 2,
      valueOptions: currentSamplesDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      editable: true,
      flex: 2
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 2,
      valueOptions: currentPersonnelDropdown,
      editable: true,
      type: 'singleSelect'
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      editable: true,
      flex: 2
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      renderCell: (params) => {
        if (params.value == 0 || params.value == 1) return params.value;
        return '';
      },
      editable: true,
      flex: 2
    },
    {
      field: 'silence',
      headerName: 'Silence?',
      editable: true,
      flex: 2
    },
    {
      field: 'silenceReason',
      headerName: 'Reason for Silencing',
      editable: true,
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      editable: true,
      flex: 2
    }
  ];

  const silencedDataEntryTableColumnsReadOnly = [
    {
      field: 'location',
      headerName: 'Location',
      flex: 4
    },
    {
      field: 'method',
      headerName: 'Method',
      flex: 2
    },
    {
      field: 'parameter',
      headerName: 'Parameter',
      flex: 2
    },
    {
      field: 'instrument',
      headerName: 'Instrument',
      flex: 2
    },
    {
      field: 'sampleName',
      headerName: 'Sample Name',
      flex: 2
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      sortComparator: dayTimeComparator,
      flex: 3
    },
    {
      field: 'personnel',
      headerName: 'Personnel',
      flex: 3
    },
    {
      field: 'sampleId',
      headerName: 'Sample ID',
      flex: 3
    },
    {
      field: 'setupData',
      headerName: 'Setup Data',
      flex: 2
    },
    {
      field: 'silence',
      headerName: 'Silence?',
      flex: 2
    },
    {
      field: 'silenceReason',
      headerName: 'Reason for Silencing',
      flex: 2
    },
    {
      field: 'result',
      headerName: 'Result',
      flex: 2
    }
  ];

  const renderDataTableSection = (renderRadioValue) => {
    if (renderRadioValue === '1') {
      if (!recheckAlert) {
        return (
          <>
            <div className="overview-chart-header">
              <h4>Scheduled Data</h4>
            </div>
            {renderDataSuccessAlert(addDataAlert)}
            {renderScheduledDataTable(featureFlags)}
          </>
        );
      }
      return (
        <>
          <div className="overview-chart-header">
            <h4>Recheck Data</h4>
          </div>
          {renderAlert(recheckAlert)}
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={dataToRecheck}
            header={recheckDataEntryTableColumns}
            action={['delete', 'edit']}
            deleteRowData={deleteRow}
          />
          <div className="overview-chart-header">
            <h4>Scheduled Data</h4>
          </div>
          <CustomTable
            numberOfRows={pageSizeDefault}
            data={currentScheduledSamples}
            setData={setCurrentScheduledSamples}
            header={dataEntryTableColumns}
            action={['delete', 'edit']}
            deleteRowData={deleteRow}
          />
        </>
      );
    }
    return (
      <>
        <div className="overview-chart-header">
          <h4>Silenced Data</h4>
        </div>
        <CustomTable
          numberOfRows={pageSizeDefault}
          data={currentSilencedData}
          header={silencedDataEntryTableColumns}
          action={['delete', 'edit']}
          deleteRowData={deleteRow}
        />
      </>
    );
  };

  const renderDataTableReadOnly = (renderRadioValue) => {
    if (renderRadioValue === '1') {
      if (!recheckAlert) {
        return (
          <>
            <div className="overview-chart-header">
              <h4>Scheduled Data</h4>
            </div>
            <CustomTable
              data={currentScheduledSamples}
              header={dataEntryTableColumnsReadOnly}
              numberOfRows={pageSizeDefault}
            />
          </>
        );
      }
      return (
        <>
          <div className="overview-chart-header">
            <h4>Recheck Data</h4>
          </div>
          {renderAlert(recheckAlert)}
          <CustomTable
            data={dataToRecheck}
            header={recheckDataEntryTableColumnsReadOnly}
            numberOfRows={pageSizeDefault}
          />
          <div className="overview-chart-header">
            <h4>Scheduled Data</h4>
          </div>
          <CustomTable
            data={currentScheduledSamples}
            header={dataEntryTableColumnsReadOnly}
            numberOfRows={pageSizeDefault}
          />
        </>
      );
    }
    return (
      <>
        <div className="overview-chart-header">
          <h4>Silenced Data</h4>
        </div>
        <CustomTable
          data={currentSilencedData}
          header={silencedDataEntryTableColumnsReadOnly}
          numberOfRows={pageSizeDefault}
        />
      </>
    );
  };

  const renderTablesWithPermissions = (renderRadioValue) => {
    const readOnly = checkReadOnly(permissionData, SCREEN.DATA_ENTRY);
    if (readOnly) {
      return renderDataTableReadOnly(renderRadioValue);
    }
    return renderDataTableSection(renderRadioValue);
  };

  useEffect(() => {
    if (Array.isArray(methods) && methods.length > 0) {
      const methodsDropdown = [];
      methods.forEach((method) => {
        const methodToReturn = {
          id: method.id,
          value: `${method.method}`,
          label: `${method.method}`
        };
        const parameterToReturn = {
          value: method.parameter,
          label: method.parameter
        };
        if (!methodsDropdown.find((dropdownItem) => dropdownItem.value === `${method.method}`)) {
          methodsDropdown.push(methodToReturn);
        }
      });
      methodsDropdown.sort(textSort);
      setCurrentMethodsDropdown(methodsDropdown);
    }
  }, [methods]);

  // eslint-disable-next-line no-unused-vars
  const [columnToDisplay, setColumnToDisplay] = useState(dataEntryTableColumns);

  const addData = (
    aCurrentLocation,
    aCurrentMethod,
    aCurrentInstrument,
    aCurrentSampleName,
    rows,
    totalRowsToSave,
    rowsToAddNew
  ) => {
    if (!aCurrentLocation || !aCurrentMethod || !aCurrentInstrument || !aCurrentSampleName) {
      // eslint-disable-next-line no-alert
      alert('Need to select location, method, sample name, and instrument');
    } else {
      const addDataObject = {
        location: aCurrentLocation,
        method: aCurrentMethod,
        instrument: aCurrentInstrument,
        rows
      };
      setLoading(true);
      addDataFunction(addDataObject)
        .then(() => {
          setAddDataAlert(true);
          totalRowsToSave.forEach((row) => {
            deleteScheduledSample(row.id);
          });
          rowsToAddNew.forEach((row) => {
            createScheduledSamples(row);
          });
          const runDataObject = {
            location: aCurrentLocation,
            method: aCurrentMethod,
            instrument: aCurrentInstrument,
            sampleName: aCurrentSampleName
          };
          runDataFunction(runDataObject);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }; // addData

  const deleteDataEntry = () => {
    if (deleteList.length > 0) {
      deleteList.forEach((item) => {
        deleteScheduledSample(item.id);
      });
      setDeleteList([]);
    }
  };

  const saveParsedData = (currentRowsToSave) => {
    const fullKeys = [];
    currentRowsToSave.forEach((rowIn) => {
      const row = rowIn;
      const adequateRowData =
        row.location !== '' &&
        row.method !== '' &&
        row.parameter !== '' &&
        row.instrument !== '' &&
        row.sampleName !== '';
      if (adequateRowData) {
        row.fullKey = `${row.location}|${row.method}|${row.sampleName}|${row.instrument}|${row.parameter}`;
        fullKeys.push(row.fullKey);
      }
    });
    const uniqueFullKeys = uniq(fullKeys);
    uniqueFullKeys.forEach((fullKey) => {
      const currentRows = currentRowsToSave.filter((row) => row.fullKey === fullKey);
      addData(
        currentRows[0].location,
        currentRows[0].method,
        currentRows[0].instrument,
        currentRows[0].sampleName,
        currentRows,
        [],
        []
      );
    });
    setCurrentScheduledSamples([]);
  };

  const saveData = (currentRowsToSave) => {
    const parsedRowsToSave = currentRowsToSave.filter((row) => row.result !== '');
    if (recheckAlert) {
      parsedRowsToSave.forEach((row) => {
        if (row.silence === 'Yes') {
          createSilencedData(row);
        }
      });
    } else {
      deleteDataEntry();
      const fullKeys = [];
      parsedRowsToSave.forEach((rowIn) => {
        const row = rowIn;
        row.dateTime = moment(row.dateTime, [
          'MM/DD/YYYY hh:mm A',
          'M/D/YYYY hh:mm A'
        ]).toISOString();
        const adequateRowData =
          row.location !== '' &&
          row.method !== '' &&
          row.parameter !== '' &&
          row.instrument !== '' &&
          row.sampleName !== '';
        if (adequateRowData) {
          row.fullKey = `${row.location}|${row.method}|${row.sampleName}|${row.instrument}|${row.parameter}`;
          fullKeys.push(row.fullKey);
        }
      });
      const uniqueFullKeys = uniq(fullKeys);
      uniqueFullKeys.forEach((fullKey) => {
        const rowsToAddNew = parsedRowsToSave
          .filter((row) => !!row.isNew && row.fullKey === fullKey)
          .map((item) => {
            return {
              dateTime: moment().toISOString(),
              fullKey,
              instrument: item.instrument,
              location: item.location,
              method: item.method,
              parameter: item.parameter,
              sampleName: item.sampleName
            };
          });
        const currentRows = parsedRowsToSave
          .filter((row) => row.fullKey === fullKey)
          .map((item) => {
            delete item.isNew;
            delete item.delete;
            return { ...item, dateTime: moment().toISOString() };
          });

        addData(
          currentRows[0].location,
          currentRows[0].method,
          currentRows[0].instrument,
          currentRows[0].sampleName,
          currentRows,
          parsedRowsToSave,
          rowsToAddNew
        );
      });
    }
  };

  const handleResults = (results) => {
    const currentRowsToUpdate = [];
    results.forEach((entry, index) => {
      if (index > 0) {
        const entryToAdd = {
          location: entry[0],
          method: entry[1],
          parameter: entry[2],
          instrument: entry[3],
          sampleName: entry[4],
          dateTime: moment(entry[5], ['MM/DD/YYYY hh:mm', 'M/D/YYYY hh:mm']).toISOString(),
          personnel: entry[6],
          sampleId: entry[7],
          setupData: entry[8],
          result: entry[9],
          delete: ''
        };
        currentRowsToUpdate.push(entryToAdd);
      }
    });
    saveParsedData(currentRowsToUpdate);
  };

  return (
    <div>
      <div className="header-button-container">
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={radio.value}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-primary' : 'outline-primary'}
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={handleRadioChange}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </div>
      <div className="data-entry-container">
        <Col>{renderTablesWithPermissions(radioValue)}</Col>
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Row',
              () => addRow(),
              SCREEN.DATA_ENTRY,
              RULES.CREATE,
              permissionData
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permissionData,
            SCREEN.DATA_ENTRY,
            RULES.CREATE
          )}
        </div>
        <div>
          {/* <PrimaryButton
            clicked={() => saveData(currentScheduledSamples)}
            text="Save data"
          ></PrimaryButton> */}
          {renderButtonWithPermissions(
            'Save Data',
            () => saveData(currentScheduledSamples),
            SCREEN.DATA_ENTRY,
            RULES.SAVE,
            permissionData,
            true,
            isLoading
          )}
        </div>
        {/* <ToastComponent /> */}
      </div>
    </div>
  );
}

export default DataEntrySection;
