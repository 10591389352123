import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  Label
} from 'recharts';
import { randomLightColor } from '../../../util/util';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const description = `${payload[0].value.toFixed(2)}%`;
    return (
      <div className="custom-tooltip">
        {label}: {description}
      </div>
    );
  }

  return null;
};

export const SQCBarChart = ({ data }) => {
  const total = data.reduce((a, b) => a + b.value, 0);
  return (
    <BarChart
      width={600}
      height={400}
      data={data.map((row) => ({ ...row, value: (row.value / total) * 100 }))}
      barSize={20}
      margin={{
        right: 10,
        left: -10
      }}
    >
      <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
      <YAxis
        tickFormatter={(tick) => {
          return `${tick}%`;
        }}
      />
      <Tooltip content={<CustomTooltip />} />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar dataKey="value">
        {data.map((entry, index) => (
          <Cell key={index} fill={randomLightColor(index)} />
        ))}
      </Bar>
    </BarChart>
  );
};
