import { Button } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

const ModalFormDocument = ({
  show,
  handleClose,
  handleSave,
  data,
  allLocations,
  inventorySettingsDocumentType
}) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      id: data?.id || '',
      documentId: data?.documentId || '',
      location: data?.location || '',
      documentType: data?.documentType || '',
      description: data?.description || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      documentId: data?.documentId || '',
      location: data?.location || '',
      documentType: data?.documentType || '',
      description: data?.description || ''
    });
  }, [show]);

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(handleSave)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Document' : 'Add Document'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Document ID</Form.Label>
                <Form.Control name="documentId" {...register('documentId', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Document Type</Form.Label>
                <Form.Select name="documentType" {...register('documentType', { required: true })}>
                  <option value="">-- Select --</option>
                  {inventorySettingsDocumentType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Location</Form.Label>
                <Form.Select name="location" {...register('location', { required: true })}>
                  <option value="">-- Select --</option>
                  {allLocations.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}></Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="description"
                  {...register('description', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormDocument;
