import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import {
  setInventoryEquipments,
  useGetCompanies,
  useInventoryEquipments,
  useInventorySettings,
  useInventoryVendors
} from '../../../util/db';
import { listInstrumentColumns } from './Columns';
import ModalFormInstrument from '../Modal/ModalFormInstrument';
import { SETTING_TYPE } from '../TabSettings/DefineCategories';

const TabInstruments = () => {
  const [filterModel, setFilterModel] = useState({});
  const { data: instruments = [] } = useInventoryEquipments(filterModel);
  const { data: vendors = [] } = useInventoryVendors();
  const { data: companies } = useGetCompanies();
  const { data: inventorySettingsInstrumentStatus = [] } = useInventorySettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const [allLocations, setAllLocations] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      const updatedLocationsPersonnel = [];
      companies.forEach((company) => {
        const currentLocation = `${company.region} - ${company.area} - ${company.laboratory}`;
        const locationToPush = {
          name: currentLocation,
          id: currentLocation,
          value: currentLocation,
          label: currentLocation
        };
        const locationExists = updatedLocationsPersonnel.find(
          (location) => location.name === currentLocation
        );
        if (!locationExists) {
          updatedLocationsPersonnel.push(locationToPush);
        }
      });
      setAllLocations(updatedLocationsPersonnel);
    }
  }, [companies]);

  const handleSave = async (data) => {
    await setInventoryEquipments(data);
    setOpen(false);
  };

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  return (
    <>
      <div className="top-actions">
        <Button variant="contained" onClick={() => setOpen(true)}>
          Create new instrument
        </Button>
        <ModalFormInstrument
          show={isOpen}
          handleClose={() => setOpen(false)}
          handleSave={handleSave}
          allLocations={allLocations}
          vendors={vendors}
          inventorySettingsInstrumentStatus={inventorySettingsInstrumentStatus}
        />
      </div>
      <div className="filter">
        <p>
          <strong>Filter data by:</strong>
        </p>
        <div>
          <Row>
            <Form.Group as={Col} controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Select onChange={(event) => handleChange('location', event)}>
                <option value="">All locations</option>
                {allLocations.map((option) => (
                  <option key={option.name} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="days">
              <Form.Label>Instrument Status</Form.Label>
              <Form.Select onChange={(event) => handleChange('status', event)}>
                <option value="">All status</option>
                {inventorySettingsInstrumentStatus.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col}></Form.Group>
            <Form.Group as={Col}></Form.Group>
            <Form.Group as={Col} controlId="vendors">
              <Form.Label>Search</Form.Label>
              <Form.Control
                placeholder="All fields"
                onChange={(event) => setSearchValue(event.target.value)}
              />
            </Form.Group>
          </Row>
        </div>
      </div>
      <div className="grid-view">
        <h2>Instruments</h2>
        <CustomTable
          numberOfRows={20}
          data={instruments
            .map((row) => {
              const dataStatus = inventorySettingsInstrumentStatus.find(
                (item) => item.id === row.status
              );
              return { ...row, status: dataStatus?.name };
            })
            .filter((row) => {
              if (searchValue) {
                return (
                  row.serialNumber.includes(searchValue) ||
                  row.location.toLowerCase().includes(searchValue.toLowerCase()) ||
                  row.name.toLowerCase().includes(searchValue.toLowerCase())
                );
              } else {
                return true;
              }
            })}
          header={listInstrumentColumns}
          sort={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
          rowHeight="auto"
          sx={{
            '.MuiDataGrid-cell': {
              minHeight: '52px !important'
            },
            '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
              display: 'none'
            }
          }}
        />
      </div>
    </>
  );
};

export default TabInstruments;
