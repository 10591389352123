import { Button } from '@mui/material';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from '../../../util/router';

const ModalAddVendor = ({ show, handleClose, handleSave, vendors = [] }) => {
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    reset({});
  }, [show]);

  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(handleSave)}>
          <Modal.Header closeButton>Add Vendor</Modal.Header>
          <Modal.Body>
            {vendors.length
              ? vendors.map((item) => (
                  <Form.Group key={`${item.id}-${Math.random() * 1000}`}>
                    <Form.Check
                      label={`${item.companyName} - ${item.department} - ${item.contactName}`}
                      {...register(item.id)}
                    />
                  </Form.Group>
                ))
              : ''}
            <Form.Group>
              <Button component={Link} to="/inventory/settings/vendors">
                + Add new vendor
              </Button>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            <Button variant="contained" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalAddVendor;
